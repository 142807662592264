import {cmpApiRoot} from "./helper";
import {usePreprod} from "../common/utils/helper";
import {CmpConfig} from "../api/model/cmp/config/CmpConfig";
import {CmpConfigVersion} from "../api/model/cmp/config/CmpConfigVersion";

export class CmpPreviewHelper {
    static path = "/preview/preview.html?";

    static getPath(): string {
        return usePreprod ? `${this.path}&apiRoot=${cmpApiRoot}` : this.path;
    }

    static getUrlForVersion(version: CmpConfigVersion, forCCPA?: boolean, forToolbar?: boolean): string {
        const uiVersion = forCCPA ? version.ccpaUIVersion : version.gdprUIVersion;
        return `${this.getPath()}&version=${uiVersion.name}${forToolbar ? "&toolbar" : ""}`;
    }

    static openPreview(cmpConfig: CmpConfig, version?: CmpConfigVersion, forCCPA?: boolean) {
        if (!version) {
            version = cmpConfig.getConfigVersion();
        }

        const url = `${this.getUrlForVersion(version, forCCPA)}&pa=${cmpConfig.id_partner}&c=${cmpConfig.id}`;
        window.open(url, "_blank");
    }
}
