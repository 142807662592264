import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {MODAL_PAGE_SIZE, TranslationPortalFile} from "../../utils/constants";
import {ButtonLinkCancel, ButtonValidate, FieldBlock, FlexContentDirection, FormLayoutButtons, FormLayoutRows, InputCheckbox, ModalContent, ModalNew, ModalTitle, SearchBar, Table, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import {Partner} from "../../utils/Partner";

type ModalSelectPartnersProps = {
    active: boolean;
    partners: Partner[];
    selectedItems: Partner[];
    onClose: () => void;
    onSubmit: (partners: Partner[]) => void;
}

const ModalSelectPartners: FunctionComponent<ModalSelectPartnersProps> = ({active, partners, selectedItems, onClose, onSubmit}) => {
    const {t: textConfigPartners} = useTranslation(TranslationPortalFile.CONFIG_PARTNERS);
    const [currentPartners, setCurrentPartners] = useState<Partner[]>([]);
    const [selectedPartners, setSelectedPartners] = useState<Partner[]>(selectedItems);
    const [query, setQuery] = useState("");

    const isAllCurrentPartnersSelected = () => {
        return !!currentPartners.length && currentPartners.filter(({id}) => selectedPartners.find((selected) => selected.id === id)).length === currentPartners.length;
    };

    const handleSelectAll = () => {
        if (isAllCurrentPartnersSelected()) {
            setSelectedPartners((prev) => (prev.filter(({id}) => !currentPartners.map(({id}) => id).includes(id))));
        } else {
            setSelectedPartners((prev) => ([...prev, ...currentPartners]));
        }
    };

    const handleSelectPartner = (partner: Partner) => {
        let newSelectedPartners = [...selectedPartners];
        if (newSelectedPartners.find(({id, type}) => partner.id === id && partner.type === type)) {
            newSelectedPartners = newSelectedPartners.filter(({id, type}) => partner.id !== id || partner.type !== type);
        } else {
            newSelectedPartners.push(partner);
        }
        setSelectedPartners(newSelectedPartners);
    };

    useEffect(() => {
        const newCurrentPartners = partners.filter((item) => `${item.id} ${item.name}`.toLowerCase().includes(query.toLowerCase()));
        setCurrentPartners(newCurrentPartners);
    }, [query, partners]);

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalContent>
                <ModalTitle>{textConfigPartners("add_partners")}</ModalTitle>
                <FormLayoutRows>
                    <FieldBlock content={{direction: FlexContentDirection.COLUMN}}>
                        <SearchBar placeholder={textConfigPartners("search.partner")} value={query} onChange={setQuery}/>
                        <Table
                            columns={[
                                {width: 10, label: <InputCheckbox checked={isAllCurrentPartnersSelected()} onChange={handleSelectAll}/>, styles: TableColumnStyle.ALIGN_CENTER},
                                {width: 10, label: textConfigPartners("field.list"), styles: TableColumnStyle.ALIGN_CENTER},
                                {width: 15, label: textConfigPartners("field.id"), styles: TableColumnStyle.ALIGN_CENTER},
                                {width: 65, label: textConfigPartners("field.partner_name")}
                            ]}
                            pagination={MODAL_PAGE_SIZE}
                        >
                            {currentPartners.map((partner) =>
                                <TableRow key={`${partner.type}-${partner.id}`} onClick={() => handleSelectPartner(partner)}>
                                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                        <InputCheckbox
                                            checked={!!selectedPartners.find(({id, type}) => partner.id === id && partner.type === type)}
                                            onChange={() => {}}
                                        />
                                    </TableColumn>
                                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{partner.logo}</TableColumn>
                                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{partner.id}</TableColumn>
                                    <TableColumn>{partner.name}</TableColumn>
                                </TableRow>
                            )}
                        </Table>
                    </FieldBlock>
                    <FormLayoutButtons>
                        <ButtonLinkCancel onClick={onClose}/>
                        <ButtonValidate onClick={() => onSubmit(selectedPartners)}/>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalSelectPartners;
