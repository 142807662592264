import {MENU} from "./Menu";
import {ModalSupportUiEvent} from "../component/modal/ModalSupport";
import {Origin} from "../common/api/model/Origin";
import {pathHome} from "../common/utils/constants";
import {pathTermsOfSale} from "./constants";
import {PortalConfig} from "../common/api/PortalConfig";
import {sirdataDataPrivacyPolicyUrl} from "./helper";
import {UIEventManager} from "../common/utils/UIEventManager";

export const PORTAL = new PortalConfig(Origin.CMP, pathHome)
    .withMenuItems(MENU)
    .withShortcuts([
        {target: () => UIEventManager.emit(ModalSupportUiEvent), icon: {name: "mail"}, label: "menu.contact_support"}
    ])
    .withFooter({
        legalNotice: true,
        cookiesSettings: true,
        items: [
            {label: "terms_of_sale", path: pathTermsOfSale},
            {label: "privacy_policy", path: sirdataDataPrivacyPolicyUrl}
        ]
    });
