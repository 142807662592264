import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {Alert, AlertSeverity, Button, ButtonSize, ButtonStyle, CircleStep, ContentBlock, IconTooltip, LayoutRows, Loadable, MainContentPageHeader, SearchField, SearchToolbar, Select, SvgSirdataLogoMidnight, Table, TableColumnStyle} from "@sirdata/ui-lib";
import clsx from "clsx";
import {findStacksFromPurposes} from "./stacks";
import {session} from "../../api/ApiSession";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {CmpConfigScope} from "../../api/model/cmp/config/CmpConfigScope";
import {CmpConfigSettings} from "../../api/model/cmp/config/CmpConfigSettings";
import {FreemiumList} from "../../api/model/cmp/list/FreemiumList";
import {VendorField} from "../../api/model/cmp/list/global-vendor-list/VendorField";
import {GlobalVendorList} from "../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {Network} from "../../api/model/cmp/list/network-list/Network";
import {NetworkList} from "../../api/model/cmp/list/network-list/NetworkList";
import {SirdataList} from "../../api/model/cmp/list/sirdata-list/SirdataList";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {sortItems} from "../../common/utils/helper";
import ModalNetwork from "../../component/modal/ModalNetwork";
import ModalSelectPartners from "../../component/modal/ModalSelectPartners";
import {ConfigHeader, PartnerRow} from "../../component/snippet";
import {PAGE_SIZE, TranslationPortalFile} from "../../utils/constants";
import {Partner} from "../../utils/Partner";
import {PARTNER_TYPES, PartnerType} from "../../utils/PartnerType";
import {UIEventManager} from "../../common/utils/UIEventManager";
import CmpConfigStepper, {CmpConfigStepName} from "../../utils/CmpConfigStepper";
import {GoogleACProviderList} from "../../api/model/cmp/list/google-ac-provider-list/GoogleACProviderList";

const PROVIDER_SCOPE_PARTNER_LIMIT = 200;

function ConfigPartners() {
    const {t: textConfigPartners} = useTranslation(TranslationPortalFile.CONFIG_PARTNERS);
    const history = useHistory();
    const params = useParams<{id: string}>();
    const [isLoading, setLoading] = useState(true);

    const [cmpConfig, setCmpConfig] = useState<CmpConfig>(new CmpConfig());
    const [isEditMode, setEditMode] = useState(true);
    const [hasUnsavedChanges, setUnsavedChanges] = useState(false);

    const [globalVendorList, setGlobalVendorList] = useState<GlobalVendorList>(new GlobalVendorList());
    const [sirdataList, setSirdataList] = useState<SirdataList>(new SirdataList());
    const [freemiumList, setFreemiumList] = useState<FreemiumList>(new FreemiumList());
    const [freemiumSirdataPartners, setFreemiumSirdataPartners] = useState<Partner[]>([]);
    const [networkList, setNetworkList] = useState<NetworkList>(new NetworkList());
    const [isFreemiumLicense, setFreemiumLicense] = useState(false);
    const [isUseScopeProvider, setUseScopeProvider] = useState(false);
    const [partners, setPartners] = useState<Partner[]>([]);

    const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]);
    const [selectedNetworkPartners, setSelectedNetworkPartners] = useState<Partner[]>([]);
    const [selectedNetworkLists, setSelectedNetworkLists] = useState<number[]>([]);

    const [query, setQuery] = useState("");
    const [selectedPartnerType, setSelectedPartnerType] = useState<PartnerType>();
    const [currentPartners, setCurrentPartners] = useState<Partner[]>([]);

    const [isShowModalSelectPartners, setShowModalSelectPartners] = useState(false);
    const [activeNetwork, setActiveNetwork] = useState<Network>();

    const handleOpenNetwork = (id: number | undefined) => {
        setActiveNetwork(networkList.networks.find((item) => item.id === id));
    };

    const handleSelectPartners = (selectedPartners: Partner[]) => {
        setUnsavedChanges(true);
        setSelectedPartners(selectedPartners);
        setShowModalSelectPartners(false);
    };

    const handleRemovePartner = (partner: Partner) => {
        setUnsavedChanges(true);
        const newSelectedPartners = selectedPartners.filter((it) => partner.id !== it.id || partner.type !== it.type);
        setSelectedPartners(newSelectedPartners);
    };

    const handleSelectNetworkList = (id: number) => {
        setUnsavedChanges(true);
        const newSelectedNetworkLists = [...selectedNetworkLists];
        if (newSelectedNetworkLists.find((it) => it === id)) {
            newSelectedNetworkLists.splice(newSelectedNetworkLists.indexOf(id), 1);
        } else {
            newSelectedNetworkLists.push(id);
        }
        setSelectedNetworkLists(newSelectedNetworkLists);
    };

    const getPartnerCount = () => {
        const mapPartners: Map<string, Partner> = new Map<string, Partner>();
        [...freemiumSirdataPartners, ...selectedPartners, ...selectedNetworkPartners].forEach((it) => {
            mapPartners.set(`${it.type}-${it.id}`, it);
        });
        return Array.from(mapPartners.values()).length;
    };

    const getPartnerSelectedNetworkLists = (partner: Partner) => {
        return networkList.networks.filter((network) => selectedNetworkLists.includes(network.id)).filter((network) => {
            return (partner.isIABVendor && network.hasIABVendor(partner.id)) ||
                   (partner.isGoogleProvider && network.hasGoogleProvider(partner.id)) ||
                   (partner.isSirdataVendor && network.hasSirdataVendor(partner.id));
        });
    };

    const buildStacks = (): number[] | undefined => {
        if (!cmpConfig.settings.vendorList.stacks?.length) {
            return undefined;
        }

        // For premium license all vendors are enabled if no one is selected
        // Additionally network lists are likely to contain all purposes
        const hasSelectedPartners = !!(selectedNetworkLists.length + selectedPartners.length);
        if ((!isFreemiumLicense && !hasSelectedPartners) || selectedNetworkLists.length) {
            return findStacksFromPurposes(globalVendorList.stacks, globalVendorList.purposes.map((it) => it.id));
        }

        let purposes: number[] = [];

        if (isFreemiumLicense) {
            freemiumList.vendors.forEach((id) => {
                const vendor = globalVendorList.getVendor(id);
                if (vendor) {
                    purposes = [...purposes, ...vendor.purposes, ...vendor.legIntPurposes];
                }
            });
            freemiumList.sirdataVendors.forEach((id) => {
                const sirdataVendor = sirdataList.getVendor(id);
                if (sirdataVendor) {
                    purposes = [...purposes, ...sirdataVendor.purposes, ...sirdataVendor.legIntPurposes];
                }
            });
        }

        selectedPartners.filter((it) => it.isIABVendor).forEach((vendor) => {
            purposes = [...purposes, ...vendor.purposes, ...vendor.legIntPurposes];
        });
        selectedPartners.filter((it) => it.isSirdataVendor).forEach((vendor) => {
            purposes = [...purposes, ...vendor.purposes, ...vendor.legIntPurposes];
        });
        if (selectedPartners.filter((it) => it.isGoogleProvider).length) {
            purposes = [...purposes, ...GoogleACProviderList.TCF_PURPOSES];
        }

        if (cmpConfig.settings.publisherPurposes?.standardPurposes?.length) {
            purposes = [...purposes, ...cmpConfig.settings.publisherPurposes?.standardPurposes.map((purpose) => purpose.id)];
        }
        if (cmpConfig.settings.vendorList.disabledPurposes?.length) {
            purposes = purposes.filter((id) => !cmpConfig.settings.vendorList.disabledPurposes?.includes(id));
        }

        return findStacksFromPurposes(globalVendorList.stacks, purposes);
    };

    const handleSave = async () => {
        try {
            if (isEditMode && !hasUnsavedChanges) {
                return;
            }

            if (cmpConfig.step === CmpConfigStepper.getPreviousStep(CmpConfigStepName.PARTNERS)?.name) {
                cmpConfig.step = CmpConfigStepName.PARTNERS;
            }

            const selectedIabVendors = selectedPartners.filter((partner) => partner.isIABVendor).map((it) => it.id);
            const selectedGoogleProviders = selectedPartners.filter((partner) => partner.isGoogleProvider).map((it) => it.id);
            const selectedSirdataVendors = selectedPartners.filter((partner) => partner.isSirdataVendor).map((it) => it.id);

            cmpConfig.settings = new CmpConfigSettings(cmpConfig.settings);
            const {vendorList} = cmpConfig.settings;
            vendorList.stacks = buildStacks();
            vendorList.vendors = selectedIabVendors.length ? selectedIabVendors : undefined;
            vendorList.googleProviders = selectedGoogleProviders.length ? selectedGoogleProviders : undefined;
            vendorList.sirdataVendors = selectedSirdataVendors.length ? selectedSirdataVendors : undefined;
            vendorList.networks = selectedNetworkLists.length ? selectedNetworkLists : undefined;

            await session.restCmpConfig.update(cmpConfig);
            setUnsavedChanges(false);
        } catch (e) {
            UIEventManager.alert(textConfigPartners("error.update_partners"), AlertSeverity.DANGER);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const newCmpConfig = await session.restCmpConfig.get(params.id);
                newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
                setCmpConfig(newCmpConfig);
                setEditMode(!newCmpConfig.step);
                setUseScopeProvider(newCmpConfig.settings.scope === CmpConfigScope.PROVIDER.name);

                const globalVendorList = await session.restList.getGlobalVendorList();
                setGlobalVendorList(globalVendorList);
                const sirdataList = await session.restList.getSirdataList();
                setSirdataList(sirdataList);
                const googleACProviderList = await session.restList.getGoogleACProviderList();
                const networkList = await session.restList.getNetworkList();
                setNetworkList(networkList);
                const premiumLicense = await session.isPremium();
                setFreemiumLicense(!premiumLicense);

                let partners: Partner[] = [];
                partners.push(...globalVendorList.vendors.filter((vendor) => !vendor.deletedDate).map((vendor) => new Partner(vendor, PartnerType.IAB)));
                partners.push(...googleACProviderList.providers.map((provider) => new Partner(provider, PartnerType.Google)));
                partners.push(...sirdataList.vendors.filter((sirdataVendor) => (premiumLicense || !sirdataVendor.premium) && !sirdataVendor.deletedDate).map((sirdataVendor) => new Partner(sirdataVendor, PartnerType.Sirdata)));
                partners = sortItems(partners, VendorField.NAME);
                setPartners(partners);

                // Sirdata mandatory partners for freemium license
                if (!premiumLicense) {
                    const freemiumList = await session.restList.getFreemiumList();
                    setFreemiumList(freemiumList);
                    const freemiumSirdataPartners = partners.filter((partner) => (partner.isIABVendor && freemiumList.vendors?.includes(partner.id)) || (partner.isSirdataVendor && freemiumList.sirdataVendors?.includes(partner.id)));
                    setFreemiumSirdataPartners(freemiumSirdataPartners);
                }

                const selectedNetworkLists = newCmpConfig.settings.vendorList.networks?.filter((id) => networkList.getNetwork(id)) || [];
                setSelectedNetworkLists(selectedNetworkLists);

                if (isEditMode) {
                    const {vendorList} = newCmpConfig.settings;
                    const selectedIabVendors = partners.filter((partner) => vendorList.vendors?.includes(partner.id) && partner.isIABVendor);
                    const selectedGoogleProviders = partners.filter((partner) => vendorList.googleProviders?.includes(partner.id) && partner.isGoogleProvider);
                    const selectedSirdataVendors = partners.filter((partner) => vendorList.sirdataVendors?.includes(partner.id) && partner.isSirdataVendor);
                    const selectedPartners = [...selectedIabVendors, ...selectedGoogleProviders, ...selectedSirdataVendors];
                    setSelectedPartners(selectedPartners);
                }
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode === HttpStatusCode.NOT_FOUND) {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [isEditMode, params.id, history]);

    useEffect(() => {
        const selectedNetworkPartners = partners.filter((partner) => {
            return selectedNetworkLists.some((id) => {
                const network = networkList.networks.find((item) => item.id === id);
                if (network) {
                    if ((network.vendors.includes(partner.id) && partner.isIABVendor) ||
                        (network.providers.includes(partner.id) && partner.isGoogleProvider) ||
                        (network.sirdataVendors.includes(partner.id) && partner.isSirdataVendor)
                    ) {
                        return true;
                    }
                }
                return false;
            });
        });
        setSelectedNetworkPartners(selectedNetworkPartners);
    }, [partners, networkList, selectedNetworkLists]);

    useEffect(() => {
        let newPartners = partners.filter((partner) => {
            if (![...freemiumSirdataPartners, ...selectedPartners, ...selectedNetworkPartners].find((it) => it.id === partner.id && it.type === partner.type)) return false;
            return `${partner.id} ${partner.name}`.toLowerCase().includes(query.toLowerCase());
        });
        if (selectedPartnerType) {
            newPartners = newPartners.filter((partner) => partner.type === selectedPartnerType);
        }
        setCurrentPartners(newPartners);
    }, [isEditMode, query, partners, freemiumSirdataPartners, selectedPartners, selectedNetworkPartners, selectedPartnerType]);

    return (
        <Wrapper>
            <MainHeader preventUnsaved={hasUnsavedChanges}/>
            <ConfigHeader
                step={CmpConfigStepName.PARTNERS}
                editMode={isEditMode}
                hasUnsavedChanges={hasUnsavedChanges}
                onSave={handleSave}
            />
            <MainContent>
                <MainContentPageHeader
                    title={textConfigPartners(isEditMode ? "title_edit" : "title")}
                    icon={!isEditMode ? <CircleStep step={CmpConfigStepper.getStepPosition(CmpConfigStepName.PARTNERS)}/> : undefined}
                    description={textConfigPartners(isFreemiumLicense ? "description.freemium" : "description.premium")}
                />
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        <ContentBlock header={{title: {label: textConfigPartners("preconfigured_lists").toUpperCase()}}}>
                            {networkList.networks &&
                                <div className="networks">
                                    {networkList.networks.map((network) =>
                                        <div key={network.id} className={clsx("network-item", {"active": selectedNetworkLists.includes(network.id)})} onClick={() => handleSelectNetworkList(network.id)}>
                                            <input
                                                type="checkbox"
                                                id={`network${network.id}`}
                                                name="selectedNetworkLists"
                                                checked={selectedNetworkLists.includes(network.id)}
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={() => {
                                                }}
                                            />
                                            <label htmlFor={`network${network.id}`}>
                                                <span className="network-img">
                                                    {network.id === 1 ? <SvgSirdataLogoMidnight/> : <img src={`/images/networks/logo-${network.id}.png`} alt={network.name}/>}
                                                </span>
                                                <span>{network.name}</span>
                                                <span onClick={(e) => e.stopPropagation()}>
                                                    <IconTooltip
                                                        icon={{name: "visibility"}}
                                                        text={textConfigPartners("see_list")}
                                                        cssClass="network-icon"
                                                        onClick={() => handleOpenNetwork(network.id)}
                                                    />
                                                </span>
                                            </label>
                                        </div>
                                    )}
                                </div>
                            }
                        </ContentBlock>
                        <ContentBlock>
                            <span className="h2">
                                <span className="partner-count">{getPartnerCount()}</span>
                                <span>{textConfigPartners("partner_activated", {count: getPartnerCount()}).toUpperCase()}</span>
                            </span>
                            {(isUseScopeProvider && getPartnerCount() > PROVIDER_SCOPE_PARTNER_LIMIT) &&
                                <Alert text={textConfigPartners("exceeded_partners_warning")} severity={AlertSeverity.WARNING} fullWidth/>
                            }
                            <SearchToolbar
                                searchBar={{placeholder: textConfigPartners("search.my_partners"), value: query, onChange: setQuery}}
                                actions={[
                                    <Button
                                        key={0}
                                        size={ButtonSize.MEDIUM}
                                        style={ButtonStyle.PRIMARY_MIDNIGHT}
                                        icon={{name: "add_circle"}}
                                        onClick={() => setShowModalSelectPartners(true)}
                                    >
                                        {textConfigPartners("add_partners")}
                                    </Button>]}
                                canHideFilters
                            >
                                <SearchField label={textConfigPartners("field.list")}>
                                    <Select
                                        value={selectedPartnerType || ""}
                                        options={[
                                            {value: "", label: textConfigPartners("field.all")},
                                            ...PARTNER_TYPES.map((it) => ({value: it, label: it}))
                                        ]}
                                        onChange={(option) => setSelectedPartnerType(option?.value as PartnerType)}
                                    />
                                </SearchField>
                            </SearchToolbar>
                            <Table
                                columns={[
                                    {width: 2},
                                    {label: textConfigPartners("field.list"), width: 5, styles: TableColumnStyle.ALIGN_CENTER},
                                    {label: textConfigPartners("field.id"), width: 5, styles: TableColumnStyle.ALIGN_CENTER},
                                    {label: textConfigPartners("field.partner_name"), width: 80},
                                    {width: 10}
                                ]}
                                pagination={PAGE_SIZE}
                            >
                                {currentPartners.map((partner) =>
                                    <PartnerRow
                                        key={`row-${partner.type}-${partner.id}`}
                                        partner={partner}
                                        networks={getPartnerSelectedNetworkLists(partner)}
                                        isRemovable={selectedPartners.some((it) => it.id === partner.id && it.type === partner.type)}
                                        isFreemium={freemiumSirdataPartners.some((it) => it.id === partner.id && it.type === partner.type)}
                                        globalVendorList={globalVendorList}
                                        onRemove={() => handleRemovePartner(partner)}
                                    />
                                )}
                            </Table>
                        </ContentBlock>
                    </Loadable>
                </LayoutRows>
                <ModalSelectPartners
                    active={isShowModalSelectPartners}
                    partners={partners}
                    selectedItems={selectedPartners}
                    onClose={() => setShowModalSelectPartners(false)}
                    onSubmit={handleSelectPartners}
                />
                <ModalNetwork
                    network={activeNetwork}
                    partners={partners}
                    onClose={() => setActiveNetwork(undefined)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default ConfigPartners;
