import {Button, ButtonStyle, FormLayoutButtons, FormLayoutRows, ModalContent, ModalNew, ModalTitle, Paragraph} from "@sirdata/ui-lib";
import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {GOOGLE_SUPPORT_FORM_ID, SUPPORT_FORM_ID, SupportForm} from "../snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";

const ModalSupport: FunctionComponent = () => {
    const {t: textSupport} = useTranslation(TranslationPortalFile.SUPPORT);
    const [isActive, setActive] = useState<boolean>(false);

    useEffect(() => {
        UIEventManager.addListener(ModalSupportUiEvent, () => {
            setActive(true);
        });

        return () => {
            UIEventManager.removeAllListeners(ModalSupportUiEvent);
        };
    }, []);

    const handleClose = () => {
        setActive(false);
    };

    return (
        <>
            <ModalNew onClose={handleClose} active={isActive} keepMounted>
                <ModalContent centered>
                    <ModalTitle>{textSupport("title")}</ModalTitle>
                    <FormLayoutRows>
                        <Paragraph>{textSupport("google_customer")}</Paragraph>
                        <FormLayoutButtons>
                            <Button
                                onClick={() => {
                                    $(SUPPORT_FORM_ID).trigger("click");
                                    setActive(false);
                                }}
                                style={ButtonStyle.DEFAULT_GREEN}
                            >
                                {textSupport("action.yes")}
                            </Button>
                            <Button
                                onClick={() => {
                                    $(GOOGLE_SUPPORT_FORM_ID).trigger("click");
                                    setActive(false);
                                }}
                                style={ButtonStyle.DEFAULT_GREEN}
                            >
                                {textSupport("action.no")}
                            </Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            </ModalNew>
            <SupportForm/>
        </>
    );
};

export default ModalSupport;
export const ModalSupportUiEvent = "ModalSupport";
