import {FC, useEffect, useState} from "react";
import {ProductFruits} from "react-product-fruits";
import {useLocation} from "react-router-dom";
import {Locale} from "../../common/utils/Locale";
import {LoggedAccount} from "../../common/api/interface/LoggedAccount";
import {session} from "../../api/ApiSession";

const PF_WORKSPACE = "qCmAEAvgmWXzFbO3";

const OnboardingTool: FC = () => {
    const location = useLocation();
    const [account, setAccount] = useState<LoggedAccount>();
    const [hasOutdatedConfig, setHasOutdatedConfig] = useState(false);

    useEffect(() => {
        if (!account) {
            (async () => {
                try {
                    const account = await session.getAccount();
                    setAccount(account);
                    setHasOutdatedConfig(await session.hasOutdatedConfig());
                } catch (e) {
                }
            })();
        }
    }, [location, account]);

    if (!account) return <></>;

    return (
        <ProductFruits
            workspaceCode={PF_WORKSPACE}
            language={Locale.getCurrentLocale()}
            user={{
                username: account.id.toString(),
                signUpAt: account.createdAt,
                props: {
                    hasOutdatedConfig: hasOutdatedConfig.toString()
                }
            }}
        />
    );
};

export default OnboardingTool;
