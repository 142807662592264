import {FunctionComponent, useEffect, useState} from "react";
import {ContentBlock, FlexContent, FlexContentDirection, FlexContentJustify, FlexContentSpacing, IconTooltip, InputRadio, Loadable, ModalContent, ModalNew, ModalNewSize, ModalTitle, Paragraph, ParagraphAlignment} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {findStacksFromPurposes} from "../../../screen/configuration/stacks";
import {HttpStatusCode} from "../../../common/api/http/HttpStatusCode";
import {session} from "../../../api/ApiSession";
import {SirdataApiEvent} from "../../../common/api/CommonApiClient";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {CmpConfigVendorList} from "../../../api/model/cmp/config/CmpConfigVendorList";
import {CmpConfigSettings} from "../../../api/model/cmp/config/CmpConfigSettings";
import {Preview} from "../index";
import {GoogleACProviderList} from "../../../api/model/cmp/list/google-ac-provider-list/GoogleACProviderList";

type PurposesDisplayProps = {
    cmpConfig: CmpConfig;
    condensed: boolean;
    onChange: (condensed: boolean) => void;
}

enum PurposesDisplayMode {
    CONDENSED = "CONDENSED",
    EXPANDED = "EXPANDED"
}

const PurposesDisplay: FunctionComponent<PurposesDisplayProps> = ({cmpConfig, condensed, onChange}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textConfigPurposes} = useTranslation(TranslationPortalFile.CONFIG_PURPOSES);
    const [activeDisplayModePreview, setActiveDisplayModePreview] = useState<PurposesDisplayMode>();
    const [cmpConfigWithStacks, setCmpConfigWithStacks] = useState<CmpConfig>(new CmpConfig(cmpConfig));
    const [cmpConfigWithoutStacks, setCmpConfigWithoutStacks] = useState<CmpConfig>(new CmpConfig(cmpConfig));
    const [isLoadingPreview, setLoadingPreview] = useState(false);
    const [vendorIds, setVendorIds] = useState<number[]>([]);
    const [stackIds, setStackIds] = useState<number[]>([]);

    const handleShowPreview = (displayMode: PurposesDisplayMode) => {
        setActiveDisplayModePreview(displayMode);
        setLoadingPreview(true);
        setTimeout(() => setLoadingPreview(false), 200);
    };

    useEffect(() => {
        (async () => {
            try {
                const globalVendorList = await session.restList.getGlobalVendorList();

                const isFreemiumLicense = !(await session.isPremium());
                const freemiumList = await session.restList.getFreemiumList();

                const hasSelectedPartners = !!((cmpConfig.settings.vendorList.vendors?.length || 0) +
                    (cmpConfig.settings.vendorList.googleProviders?.length || 0) +
                    (cmpConfig.settings.vendorList.sirdataVendors?.length || 0));

                const selectedVendorIds = [...cmpConfig.settings.vendorList?.vendors || []];
                if (isFreemiumLicense) {
                    selectedVendorIds.push(...freemiumList.vendors);
                }
                let selectedVendors = globalVendorList.vendors.filter((it) => selectedVendorIds.includes(it.id));
                if (!selectedVendors.length) {
                    selectedVendors = globalVendorList.vendors;
                }

                let purposes: number[] = [];

                if ((!isFreemiumLicense && !hasSelectedPartners) || cmpConfig.settings.vendorList.networks?.length) { // Network lists are likely to contain all purposes
                    purposes = globalVendorList.purposes.map((it) => it.id);
                } else {
                    selectedVendors.forEach((vendor) => {
                        purposes = [...purposes, ...vendor.purposes, ...vendor.legIntPurposes];
                    });
                    if (cmpConfig.settings.vendorList.googleProviders?.length) {
                        purposes = [...purposes, ...GoogleACProviderList.TCF_PURPOSES];
                    }

                    const sirdataList = await session.restList.getSirdataList();
                    cmpConfig.settings.vendorList.sirdataVendors?.forEach((vendorId) => {
                        const vendor = sirdataList.getVendor(vendorId);
                        if (vendor) {
                            purposes = [...purposes, ...vendor.purposes, ...vendor.legIntPurposes];
                        }
                    });
                    if (isFreemiumLicense) {
                        freemiumList.sirdataVendors.forEach((vendorId) => {
                            const vendor = sirdataList.getVendor(vendorId);
                            if (vendor) {
                                purposes = [...purposes, ...vendor.purposes, ...vendor.legIntPurposes];
                            }
                        });
                    }

                    if (cmpConfig.settings.publisherPurposes?.standardPurposes?.length) {
                        purposes = [...purposes, ...cmpConfig.settings.publisherPurposes?.standardPurposes.map((purpose) => purpose.id)];
                    }
                    if (cmpConfig.settings.vendorList.disabledPurposes?.length) {
                        purposes = purposes.filter((id) => !cmpConfig.settings.vendorList.disabledPurposes?.includes(id));
                    }
                }
                purposes = Array.from(new Set([...purposes]));
                setStackIds(findStacksFromPurposes(globalVendorList.stacks, purposes));
                setVendorIds(selectedVendorIds);
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode === HttpStatusCode.NOT_FOUND) {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
            }
        })();
    }, [cmpConfig]);

    useEffect(() => {
        setCmpConfigWithStacks((prevState) => {
            const newCmpConfigVendorList = new CmpConfigVendorList(prevState.settings.vendorList);
            newCmpConfigVendorList.vendors = vendorIds;
            newCmpConfigVendorList.stacks = stackIds;
            const newCmpConfig = new CmpConfig(cmpConfig);
            newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
            newCmpConfig.settings.vendorList = newCmpConfigVendorList;
            return newCmpConfig;
        });
        setCmpConfigWithoutStacks((prevState) => {
            const newCmpConfigVendorList = new CmpConfigVendorList(prevState.settings.vendorList);
            newCmpConfigVendorList.vendors = vendorIds;
            newCmpConfigVendorList.stacks = [];
            const newCmpConfig = new CmpConfig(cmpConfig);
            newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
            newCmpConfig.settings.vendorList = newCmpConfigVendorList;
            return newCmpConfig;
        });
    }, [vendorIds, stackIds, cmpConfig]);

    return (
        <ContentBlock>
            <FlexContent
                direction={FlexContentDirection.ROW}
                spacing={FlexContentSpacing.LARGE}
                justify={FlexContentJustify.SPACE_EVENLY}
                cssClass={"purposes-display-section"}
            >
                {[PurposesDisplayMode.CONDENSED, PurposesDisplayMode.EXPANDED].map((mode) =>
                    <div key={mode} className="purposes-display-section__item">
                        <InputRadio
                            id={`stack-${mode}`}
                            value={mode}
                            checked={mode === PurposesDisplayMode.CONDENSED ? condensed : !condensed}
                            onChange={() => onChange(mode === PurposesDisplayMode.CONDENSED)}
                        />
                        <label className="label" htmlFor={`stack-${mode}`}>
                            <img src={`/images/${mode.toLowerCase()}.png`} alt=""/>
                        </label>
                        <div className="purposes-display-section__item__title">
                            <span>{textConfigPurposes(`tab.display.mode.${mode}`)}</span>
                            <IconTooltip
                                icon={{name: "visibility"}}
                                text={t("list.action.preview")}
                                onClick={() => handleShowPreview(mode)}
                            />
                        </div>
                    </div>
                )}
            </FlexContent>
            <Paragraph alignment={ParagraphAlignment.JUSTIFY} withSpacing>
                <span dangerouslySetInnerHTML={{__html: textConfigPurposes("tab.display.description")}}/>
            </Paragraph>
            <ModalNew
                size={ModalNewSize.MAX_WIDTH}
                active={!!activeDisplayModePreview}
                onClose={() => setActiveDisplayModePreview(undefined)}
            >
                <ModalContent>
                    <ModalTitle>{textConfigPurposes(`tab.display.preview.${activeDisplayModePreview}`)}</ModalTitle>
                    <Loadable loading={isLoadingPreview}>
                        <Preview
                            cmpConfig={activeDisplayModePreview === PurposesDisplayMode.CONDENSED ? cmpConfigWithStacks : cmpConfigWithoutStacks}
                        />
                    </Loadable>
                </ModalContent>
            </ModalNew>
        </ContentBlock>
    );
};

export default PurposesDisplay;
