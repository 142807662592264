import moment from "moment";
import numeral from "numeral";
import {Formatter} from "@sirdata/ui-lib";

import {AnalyticsColor} from "../AnalyticsColor";

export type GraphDataset = {
    label?: string;
    borderWidth?: number;
    borderColor?: string;
    backgroundColor: string | string[];
    pointBackgroundColor?: string;
    pointBorderColor?: string;
    pointRadius?: number;
    data: number[];
    fill?: string | number;
};

export type GraphData = {
    labels: string[];
    datasets: GraphDataset[];
};

export const chartPieOptions = {
    tooltips: {
        mode: "index",
        position: "nearest",
        displayColors: true,
        callbacks: {
            label: function(tooltipItem: any, data: any) {
                return " " + data.labels[tooltipItem.index] + ": " + data.datasets[0].data[tooltipItem.index] + "%";
            }
        }
    },
    responsive: true,
    legend: false
};

export const chartLineOptions = {
    barRoundness: 1,
    elements: {
        point: {
            pointStyle: "rectRounded",
            hoverRadius: 10
        }
    },
    scales: {
        xAxes: [{
            type: "time",
            offset: false,
            time: {
                unit: "day",
                displayFormats: {
                    day: "DD/MM"
                }
            },
            gridLines: {display: false}
        }],
        yAxes: [{
            stacked: true,
            ticks: {
                callback: function(value: number) {
                    return Formatter.formatNumber(value);
                }
            }
        }]
    },
    tooltips: {
        mode: "index",
        position: "nearest",
        displayColors: true,
        callbacks: {
            title: (tooltipItem: any) => moment(tooltipItem[0].xLabel).format("DD/MM/YYYY"),
            label: function(tooltipItem: any, data: any) {
                return  " " + data.datasets[tooltipItem.datasetIndex].label + ": " + numeral(tooltipItem.yLabel).format("0,0").replaceAll(",", " ");
            }
        }
    },
    legend: false,
    responsive: true
};

export const optionsRating = {
    ...chartLineOptions,
    scales: {
        xAxes: [{
            type: "time",
            offset: false,
            time: {
                unit: "day",
                displayFormats: {
                    day: "DD/MM"
                }
            },
            gridLines: {display: false}
        }],
        yAxes: [{
            stacked: true,
            offset: true,
            ticks: {max: 100, min: 0}
        }]
    },
    tooltips: {
        mode: "index",
        callbacks: {
            title: (tooltipItem: any) => moment(tooltipItem[0].xLabel).format("DD/MM/YYYY"),
            label: function(tooltipItem: any, data: any) {
                return  " " + data.datasets[tooltipItem.datasetIndex].label + ": " + tooltipItem.yLabel + "%";
            }
        }
    }
};

export const ratingOptionsUnstacked = {
    ...optionsRating,
    scales: {
        ...optionsRating.scales,
        yAxes: [
            ...optionsRating.scales.yAxes.map((it) => ({...it, stacked: false}))
        ]
    }
};

export const CHART_DATASET_DEFAULT_STYLES = {
    borderWidth: 2,
    borderColor: AnalyticsColor.HITS+"85",
    backgroundColor: [AnalyticsColor.HITS+"40"],
    pointBackgroundColor: AnalyticsColor.HITS,
    pointBorderColor: AnalyticsColor.HITS,
    pointRadius: 4
};

export const CHART_DATASET_CONSENT_STYLES = {
    ...CHART_DATASET_DEFAULT_STYLES,
    borderColor: AnalyticsColor.CONSENT+"85",
    backgroundColor: [AnalyticsColor.CONSENT+"40"],
    pointBackgroundColor: AnalyticsColor.CONSENT,
    pointBorderColor: AnalyticsColor.CONSENT,
    fill: "origin"
};

export const CHART_DATASET_OBJECT_STYLES = {
    ...CHART_DATASET_DEFAULT_STYLES,
    borderColor: AnalyticsColor.OBJECT+"85",
    backgroundColor: [AnalyticsColor.OBJECT+"40"],
    pointBackgroundColor: AnalyticsColor.OBJECT,
    pointBorderColor: AnalyticsColor.OBJECT,
    fill: 0
};

export const CHART_DATASET_NO_CHOICE_STYLES = {
    ...CHART_DATASET_DEFAULT_STYLES,
    borderColor: AnalyticsColor.NO_CHOICE+"85",
    backgroundColor: [AnalyticsColor.NO_CHOICE+"40"],
    pointBackgroundColor: AnalyticsColor.NO_CHOICE,
    pointBorderColor: AnalyticsColor.NO_CHOICE,
    fill: 1
};
