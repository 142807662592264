import React, {ReactNode} from "react";
import {PartnerType} from "./PartnerType";
import {Vendor} from "../api/model/cmp/list/global-vendor-list/Vendor";
import {GoogleACProvider} from "../api/model/cmp/list/google-ac-provider-list/GoogleACProvider";
import {SirdataVendor} from "../api/model/cmp/list/sirdata-list/SirdataVendor";
import {GoogleACProviderList} from "../api/model/cmp/list/google-ac-provider-list/GoogleACProviderList";

export class Partner {
    id: number;
    name: string = "";
    type: PartnerType;
    purposes: number[] = [];
    legIntPurposes: number[] = [];
    specialPurposes: number[] = [];
    features: number[] = [];
    specialFeatures: number[] = [];

    constructor(partner: Vendor | GoogleACProvider | SirdataVendor, type: PartnerType) {
        this.id = partner.id;
        this.name = partner.name;
        this.type = type;

        if (type === PartnerType.IAB) {
            this.purposes = partner.purposes;
            this.legIntPurposes = partner.legIntPurposes;
            this.specialPurposes = partner.specialPurposes;
            this.features = partner.features;
            this.specialFeatures = partner.specialFeatures;
        } else if (type === PartnerType.Google) {
            this.purposes = GoogleACProviderList.TCF_PURPOSES;
        } else if (type === PartnerType.Sirdata) {
            this.purposes = partner.purposes;
            this.legIntPurposes = partner.legIntPurposes;
        }
    }

    get isIABVendor(): boolean {
        return this.type === PartnerType.IAB;
    }

    get isGoogleProvider(): boolean {
        return this.type === PartnerType.Google;
    }

    get isSirdataVendor(): boolean {
        return this.type === PartnerType.Sirdata;
    }

    get logo(): ReactNode {
        if (this.isIABVendor) {
            return <img src="/images/logo_iab_partner_color.png" alt="IAB logo" title="IAB"/>;
        } else if (this.isGoogleProvider) {
            return <img src="/images/logo_google_partner_opacity.png" alt="Google logo" title="Google"/>;
        } else {
            return <img src="/images/logo_sirdata_partners_small.png" alt="Sirdata logo" title="Sirdata"/>;
        }
    }
}
