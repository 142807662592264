import React, {FunctionComponent, useState} from "react";
import {Action, ActionsMenu, Box, BoxRadius, BoxSpacing, ContentBlock, FlexContent, FlexContentDirection, FlexContentSpacing, Select, TranslationLibFile} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfigPublisherLegalBasis} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherLegalBasis";
import {GlobalVendorList} from "../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {CmpConfigPublisherStandardPurpose} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherStandardPurpose";
import {CmpConfigPublisherCustomPurpose} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurpose";
import {CmpConfigPublisherCustomPurposeTranslation} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurposeTranslation";
import ModalCustomPurpose from "../../modal/ModalCustomPurpose";
import ModalTranslationPurpose from "../../modal/ModalTranslationPurpose";
import {CmpConfigPublisher} from "../../../api/model/cmp/config/publisher/CmpConfigPublisher";
import {CmpConfigPublisherStandardPurposeField} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherStandardPurposeField";
import {CmpConfigPublisherCustomPurposeField} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurposeField";

type PublisherPurposesProps = {
    publisherPurposes: CmpConfigPublisher;
    globalVendorList: GlobalVendorList;
    onChange: (publisherPurposes: CmpConfigPublisher) => void;
}

const PublisherPurposes: FunctionComponent<PublisherPurposesProps> = ({publisherPurposes, globalVendorList, onChange}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textConfigPurposes} = useTranslation(TranslationPortalFile.CONFIG_PURPOSES);

    const [activeCustomPurpose, setActiveCustomPurpose] = useState<CmpConfigPublisherCustomPurpose>();
    const [activeCustomPurposeForTranslation, setActiveCustomPurposeForTranslation] = useState<CmpConfigPublisherCustomPurpose>();

    const PURPOSE_1_ID = 1;

    const handleAddPurpose = (purposeId: number) => {
        if (purposeId === 0) {
            const lastCustomPurposeId = Math.max(...(publisherPurposes.customPurposes || []).map((it) => it.id), 0);
            const newCustomPurpose = new CmpConfigPublisherCustomPurpose({
                [CmpConfigPublisherCustomPurposeField.ID]: lastCustomPurposeId + 1
            });
            setActiveCustomPurpose(newCustomPurpose);
        } else {
            const newStandardPurposes = [...publisherPurposes.standardPurposes || []];
            newStandardPurposes.push(new CmpConfigPublisherStandardPurpose({
                [CmpConfigPublisherStandardPurposeField.ID]: purposeId,
                [CmpConfigPublisherStandardPurposeField.LEGAL_BASIS]: CmpConfigPublisherLegalBasis.CONSENT
            }));
            publisherPurposes.standardPurposes = newStandardPurposes;
            onChange(publisherPurposes);
        }
    };

    const handleChangeLegalBasis = (purposeId: number) => {
        const newStandardPurposes = [...publisherPurposes.standardPurposes || []];
        const purpose = newStandardPurposes.find((it) => it.id === purposeId);
        if (purpose) {
            if (purpose.legalBasis === CmpConfigPublisherLegalBasis.CONSENT) {
                purpose.legalBasis = CmpConfigPublisherLegalBasis.LEGITIMATE_INTEREST;
            } else {
                purpose.legalBasis = CmpConfigPublisherLegalBasis.CONSENT;
            }
            publisherPurposes.standardPurposes = newStandardPurposes;
            onChange(publisherPurposes);
        }
    };

    const handleDeleteStandardPurpose = (purposeId: number) => {
        const newStandardPurposes = [...publisherPurposes.standardPurposes || []];
        newStandardPurposes.splice(newStandardPurposes.findIndex((it) => it.id === purposeId), 1);
        publisherPurposes.standardPurposes = newStandardPurposes;
        onChange(publisherPurposes);
    };

    const handleDeleteCustomPurpose = (purposeId: number) => {
        const newCustomPurposes = [...publisherPurposes.customPurposes || []];
        newCustomPurposes.splice(newCustomPurposes.findIndex((it) => it.id === purposeId), 1);
        publisherPurposes.customPurposes = newCustomPurposes;
        onChange(publisherPurposes);
    };

    const handleChangeCustomPurpose = (customPurpose: CmpConfigPublisherCustomPurpose) => {
        const newCustomPurposes = [...publisherPurposes.customPurposes || []];
        const index = newCustomPurposes.findIndex((it) => it.id === customPurpose.id);
        if (index > -1) {
            newCustomPurposes[index] = customPurpose;
        } else {
            newCustomPurposes.push(customPurpose);
        }
        publisherPurposes.customPurposes = newCustomPurposes;
        onChange(publisherPurposes);
        setActiveCustomPurpose(undefined);
    };

    const handleUpdateCustomPurposeTranslations = (purposeTranslations: Map<string, CmpConfigPublisherCustomPurposeTranslation>) => {
        const newCustomPurposes = [...publisherPurposes.customPurposes || []];
        const customPurpose = newCustomPurposes.find((it) => it.id === activeCustomPurposeForTranslation?.id);
        if (customPurpose) {
            customPurpose.translations = purposeTranslations;
            const index = newCustomPurposes.findIndex((it) => it.id === customPurpose.id);
            newCustomPurposes[index] = customPurpose;
            publisherPurposes.customPurposes = newCustomPurposes;
            onChange(publisherPurposes);
            setActiveCustomPurposeForTranslation(undefined);
        }
    };

    return (
        <>
            <ContentBlock
                cssClass={"purposes-section"}
                header={{
                    title: {label: textConfigPurposes("tab.custom.title")},
                    actions: (
                        <Select
                            value={""}
                            options={[
                                {value: "", label: textConfigPurposes("tab.custom.add_purpose"), isDisabled: true},
                                {value: 0, label: textConfigPurposes("tab.custom.add_custom_purpose")},
                                ...globalVendorList.purposes.filter((purpose) => !(publisherPurposes.standardPurposes || []).find((it) => purpose.id === it.id)).map(({id, name}) => ({value: id, label: `${textConfigPurposes("purpose")} ${id} : ${name}`}))
                            ]}
                            onChange={(option) => option && handleAddPurpose(+option.value)}
                        />
                    )}}
            >
                <Box spacing={BoxSpacing.MEDIUM} radius={BoxRadius.MD}>
                    <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.SMALL} cssClass={"purposes-section__list"}>
                        {(publisherPurposes.standardPurposes || [])
                            .sort((a, b) => a.id > b.id ? 1 : -1)
                            .map((purpose) =>
                                <div key={purpose.id} className="purposes-section__item purposes-section__item--publisher">
                                    <div>
                                        <strong>{textConfigPurposes("purpose")} {purpose.id}</strong> : {globalVendorList.getPurpose(purpose.id)?.name}
                                        <span className="purposes-section__item__legal-basis">{textConfigPurposes(`${purpose.legalBasis}`)}</span>
                                    </div>
                                    {purpose.id !== PURPOSE_1_ID ?
                                        <ActionsMenu
                                            iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                            items={[
                                                {label: purpose.legalBasis === CmpConfigPublisherLegalBasis.LEGITIMATE_INTEREST ? textConfigPurposes("tab.custom.use_consent") : textConfigPurposes("tab.custom.use_legint"), onClick: () => handleChangeLegalBasis(purpose.id)},
                                                {label: textCommon(Action.DELETE.labelKey), onClick: () => handleDeleteStandardPurpose(purpose.id), critical: true}
                                            ]}
                                        /> :
                                        <ActionsMenu
                                            iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                            items={[
                                                {label: textCommon(Action.DELETE.labelKey), onClick: () => handleDeleteStandardPurpose(purpose.id), critical: true}
                                            ]}
                                        />
                                    }
                                </div>
                            )
                        }
                        {(publisherPurposes.customPurposes || [])
                            .sort((a, b) => a.id > b.id ? 1 : -1)
                            .map((purpose) =>
                                <div key={purpose.id} className="purposes-section__item purposes-section__item--publisher">
                                    <div>
                                        <strong>{textConfigPurposes("tab.custom.purpose_custom")} {purpose.id}</strong> : {purpose.name}
                                        <span className="purposes-section__item__legal-basis">{textConfigPurposes(`${purpose.legalBasis}`)}</span>
                                    </div>
                                    <ActionsMenu
                                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                        items={[
                                            {label: textCommon(Action.EDIT.labelKey), onClick: () => setActiveCustomPurpose(purpose)},
                                            {label: textConfigPurposes("tab.custom.translate"), onClick: () => setActiveCustomPurposeForTranslation(purpose)},
                                            {label: textCommon(Action.DELETE.labelKey), onClick: () => handleDeleteCustomPurpose(purpose.id), critical: true}
                                        ]}
                                    />
                                </div>
                            )
                        }
                    </FlexContent>
                </Box>
            </ContentBlock>
            <ModalCustomPurpose
                initValue={activeCustomPurpose}
                onClose={() => setActiveCustomPurpose(undefined)}
                onSubmit={handleChangeCustomPurpose}
            />
            <ModalTranslationPurpose
                initValue={activeCustomPurposeForTranslation}
                onClose={() => setActiveCustomPurposeForTranslation(undefined)}
                onSubmit={handleUpdateCustomPurposeTranslations}
            />
        </>
    );
};

export default PublisherPurposes;
