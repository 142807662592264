import {Stack} from "../../api/model/cmp/list/global-vendor-list/Stack";

export const findStacksFromPurposes = (stacks: Stack[], purposeIds: number[]): number[] => {
    if (!(purposeIds.length && stacks.length)) {
        return [];
    }
    purposeIds = Array.from(new Set([...purposeIds]));

    let stacksToTest = stacks.filter((stack) => (stack.purposes.length && stack.purposes.length <= purposeIds.length) ? stack : false);
    stacksToTest = stacksToTest.filter((it) => it.purposes.length <= 4);
    stacksToTest.sort((a, b) => b.purposes.length - a.purposes.length);

    const selectedStacks: number[] = [];
    stacksToTest.some((stack) => {
        const found = stack.purposes.every((id) => purposeIds.includes(id));
        if (found) {
            selectedStacks.push(stack.id);
            const remainingPurposeIds = purposeIds.filter((id) => !stack.purposes.includes(id));
            selectedStacks.push(...findStacksFromPurposes(stacks, remainingPurposeIds));
            return true;
        } else {
            return false;
        }
    });
    return selectedStacks;
};
