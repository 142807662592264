import React from "react";
import {ChartComponentProps, Doughnut} from "react-chartjs-2";
import {GraphData, chartPieOptions} from "../../../utils/stat/chart_options";

interface ChartDoughnutProps extends ChartComponentProps {
    data: GraphData | undefined;
}

const ChartDoughnut: React.FC<ChartDoughnutProps> = ({data, ...rest}) => {
    if (!data) return <></>;

    return <Doughnut data={data} options={chartPieOptions} {...rest}/>;
};

export default ChartDoughnut;
