import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    Accordion,
    AccordionItem,
    AccordionItemContent,
    Alert,
    AlertSeverity,
    Box,
    BoxRadius,
    BoxSpacing,
    ButtonLink,
    ButtonLinkStyle,
    CircleStep,
    FieldBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentJustify,
    FlexContentLayout,
    FlexContentMobileDirection,
    FlexContentSpacing,
    FormLayoutButtons,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormLayoutTitle,
    InputNumber,
    InputWithLabel,
    LayoutRows,
    Loadable,
    MainContentPageHeader,
    ModalContent,
    ModalNew,
    ModalNewSize,
    ModalTitle,
    Select,
    Tag,
    TagStyle,
    ToggleSwitch
} from "@sirdata/ui-lib";

import ModalTranslationTexts from "../../component/modal/ModalTranslationTexts";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {CmpConfigCCPAField} from "../../api/model/cmp/config/CmpConfigCCPAField";
import {CmpConfigCapping} from "../../api/model/cmp/config/CmpConfigCapping";
import {CmpConfigField} from "../../api/model/cmp/config/CmpConfigField";
import {CmpConfigLanguage} from "../../api/model/cmp/config/CmpConfigLanguage";
import {CmpConfigScope} from "../../api/model/cmp/config/CmpConfigScope";
import {CmpConfigSettingsField} from "../../api/model/cmp/config/CmpConfigSettingsField";
import {CmpConfigText} from "../../api/model/cmp/config/CmpConfigText";
import {CmpConfigThemeField} from "../../api/model/cmp/config/theme/CmpConfigThemeField";
import {CMP_CONFIG_THEME_NO_CONSENT_BUTTONS, CmpConfigThemeNoConsentButton} from "../../api/model/cmp/config/theme/CmpConfigThemeNoConsentButton";
import {CMP_CONFIG_THEME_NO_CONSENT_BUTTON_STYLES, CmpConfigThemeNoConsentButtonStyle} from "../../api/model/cmp/config/theme/CmpConfigThemeNoConsentButtonStyle";
import {ConfigHeader, Preview} from "../../component/snippet";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainContent, Wrapper} from "../../common/component/widget";
import {FormLayoutMessage, MainHeader} from "../../common/component/snippet";
import {CmpConfigSettings, DEFAULT_CAPPING_IN_DAYS, DEFAULT_COOKIE_MAX_AGE_IN_DAYS, MAX_CAPPING_IN_DAYS, MAX_COOKIE_MAX_AGE_IN_DAYS, MIN_CAPPING_IN_DAYS, MIN_COOKIE_MAX_AGE_IN_DAYS} from "../../api/model/cmp/config/CmpConfigSettings";
import {session} from "../../api/ApiSession";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {TOOLBAR_OFFSET_SUFFIX} from "../../api/model/cmp/config/theme/CmpConfigThemeToolbar";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";
import CmpConfigStepper, {CmpConfigStepName} from "../../utils/CmpConfigStepper";
import {CmpConfigFlexibleRefusalField} from "../../api/model/cmp/config/CmpConfigFlexibleRefusalField";
import {CMP_CONFIG_THEME_SET_CHOICES_STYLES, CmpConfigThemeSetChoicesStyle} from "../../api/model/cmp/config/theme/CmpConfigThemeSetChoicesStyle";
import {CmpConfigTheme} from "../../api/model/cmp/config/theme/CmpConfigTheme";

function ConfigSettings() {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);
    const {t: textConfigSettings} = useTranslation(TranslationPortalFile.CONFIG_SETTINGS);
    const history = useHistory();
    const params = useParams<{id: string}>();
    const [isLoading, setLoading] = useState(true);

    const [cmpConfig, setCmpConfig] = useState<CmpConfig>(new CmpConfig());
    const [isEditMode, setEditMode] = useState(true);
    const [hasUnsavedChanges, setUnsavedChanges] = useState(false);

    const [isShowCCPAPreview, setShowCCPAPreview] = useState(false);
    const [isActiveModalTranslationTexts, setActiveModalTranslationTexts] = useState(false);

    const handleChangeConfig = (name: string, value: any) => {
        const newCmpConfig = new CmpConfig(cmpConfig);
        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);

        const nodeParts = name.split(".");
        if (nodeParts.length > 1) {
            const node = nodeParts[0];
            const attr = nodeParts[1];
            newCmpConfig.settings[node][attr] = value;
        } else {
            newCmpConfig.settings[name] = value;
        }

        setCmpConfig(newCmpConfig);
        setUnsavedChanges(true);
    };

    const handleChangeConfigThemeNoConsentButton = (type: CmpConfigThemeNoConsentButton) => {
        const newTheme = new CmpConfigTheme({...cmpConfig.settings.theme, [CmpConfigThemeField.NO_CONSENT_BUTTON]: type});
        if (type === CmpConfigThemeNoConsentButton.NONE) {
            newTheme.closeButton = true;
            newTheme.noConsentButtonStyle = CmpConfigThemeNoConsentButtonStyle.BUTTON_BIG_DEFAULT;
        } else if (cmpConfig.settings.theme.noConsentButton === CmpConfigThemeNoConsentButton.NONE) {
            newTheme.closeButton = false;
        }
        handleChangeConfig(CmpConfigSettingsField.THEME, newTheme);
    };

    const handleChangeConfigThemeNoConsentButtonStyle = (style: CmpConfigThemeNoConsentButtonStyle) => {
        const newTheme = new CmpConfigTheme({...cmpConfig.settings.theme, [CmpConfigThemeField.NO_CONSENT_BUTTON_STYLE]: style});
        if (![CmpConfigThemeNoConsentButtonStyle.BUTTON_BIG_DEFAULT, CmpConfigThemeNoConsentButtonStyle.BUTTON_BIG_PRIMARY].includes(style)) {
            newTheme.setChoicesStyle = CmpConfigThemeSetChoicesStyle.BUTTON;
        }
        handleChangeConfig(CmpConfigSettingsField.THEME, newTheme);
    };

    const handleChangeTexts = (texts: Map<string, CmpConfigText>) => {
        const newCmpConfig = new CmpConfig(cmpConfig);
        newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
        newCmpConfig.settings.texts = texts;
        setCmpConfig(newCmpConfig);
        setUnsavedChanges(true);
        setActiveModalTranslationTexts(false);
    };

    const handleSave = async () => {
        try {
            if (isEditMode && !hasUnsavedChanges) {
                return;
            }

            if (cmpConfig.step === CmpConfigStepper.getPreviousStep(CmpConfigStepName.SETTINGS)?.name) {
                cmpConfig.step = CmpConfigStepName.SETTINGS;
            }

            cmpConfig.settings.cookieMaxAgeInDays = Math.min(cmpConfig.settings.cookieMaxAgeInDays, MAX_COOKIE_MAX_AGE_IN_DAYS);
            if (cmpConfig.settings.cookieMaxAgeInDays < MIN_COOKIE_MAX_AGE_IN_DAYS) {
                cmpConfig.settings.cookieMaxAgeInDays = DEFAULT_COOKIE_MAX_AGE_IN_DAYS;
            }

            cmpConfig.settings.cappingInDays = Math.min(cmpConfig.settings.cappingInDays, MAX_CAPPING_IN_DAYS);
            if (cmpConfig.settings.cappingInDays < MIN_CAPPING_IN_DAYS) {
                cmpConfig.settings.cappingInDays = DEFAULT_CAPPING_IN_DAYS;
            }

            if (cmpConfig.settings.language) {
                cmpConfig.settings.defaultLanguage = "";
            }

            cmpConfig.settings.theme.toolbar.offset = `${cmpConfig.settings.theme.toolbar.offset}${TOOLBAR_OFFSET_SUFFIX}`;
            await session.restCmpConfig.update(cmpConfig);
            setUnsavedChanges(false);
        } catch (e) {
            UIEventManager.alert(textConfiguration("error.update_cmp"), AlertSeverity.DANGER);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const newCmpConfig = await session.restCmpConfig.get(params.id);
                newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
                newCmpConfig.settings.theme.toolbar.offset = newCmpConfig.settings.theme.toolbar.offset.replace(TOOLBAR_OFFSET_SUFFIX, "");
                setCmpConfig(newCmpConfig);
                setEditMode(!newCmpConfig.step);
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode === HttpStatusCode.NOT_FOUND) {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [params.id, history]);

    const isCCPAEnabled = !Boolean(cmpConfig.settings.ccpa.disabled);

    return (
        <Wrapper>
            <MainHeader preventUnsaved={hasUnsavedChanges}/>
            <ConfigHeader
                step={CmpConfigStepName.SETTINGS}
                editMode={isEditMode}
                hasUnsavedChanges={hasUnsavedChanges}
                onSave={handleSave}
            />
            <MainContent>
                <MainContentPageHeader
                    title={textConfigSettings("title")}
                    icon={!isEditMode ? <CircleStep step={CmpConfigStepper.getStepPosition(CmpConfigStepName.SETTINGS)}/> : undefined}
                    cssClass="configuration-main-title"
                />
                <LayoutRows>
                    <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.SMALL} layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT} mobileDirection={FlexContentMobileDirection.COLUMN}>
                        <Loadable loading={isLoading}>
                            <div>
                                <Accordion>
                                    <AccordionItem heading={<Tag label={textConfigSettings("section.language")} icon={{name: "translate"}} style={TagStyle.DEFAULT_OCEAN}/>} startOpen>
                                        <AccordionItemContent>
                                            <FormLayoutRows>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.LANGUAGE}`)}>
                                                        <Select
                                                            value={cmpConfig.settings.language}
                                                            options={[
                                                                {value: "", label: t("language.default")},
                                                                ...CmpConfigLanguage.values().sort((a, b) => t(`language.${a.name}`) > t(`language.${b.name}`) ? 1 : -1).map((it) => ({value: it.name, label: t(`language.${it.name}`)}))
                                                            ]}
                                                            onChange={(option) => option && handleChangeConfig(CmpConfigSettingsField.LANGUAGE, option.value.toString())}
                                                        />
                                                    </FieldBlock>
                                                    {!cmpConfig.settings.language &&
                                                        <FieldBlock
                                                            label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.DEFAULT_LANGUAGE}`)}
                                                            tooltip={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.DEFAULT_LANGUAGE}_tooltip`)}
                                                        >
                                                            <Select
                                                                value={cmpConfig.settings.defaultLanguage}
                                                                options={CmpConfigLanguage.values().sort((a, b) => t(`language.${a.name}`) > t(`language.${b.name}`) ? 1 : -1).map((it) => ({value: it.name, label: t(`language.${it.name}`)}))}
                                                                onChange={(option) => handleChangeConfig(CmpConfigSettingsField.DEFAULT_LANGUAGE, `${option?.value || ""}`)}
                                                                clearable
                                                            />
                                                        </FieldBlock>
                                                    }
                                                </FormLayoutColumns>
                                                <FormLayoutButtons justify={FlexContentJustify.END}>
                                                    <ButtonLink onClick={() => setActiveModalTranslationTexts(true)} icon={{name: "east"}} style={ButtonLinkStyle.MIDNIGHT}>
                                                        {textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.TEXTS}.label`)}
                                                    </ButtonLink>
                                                </FormLayoutButtons>
                                            </FormLayoutRows>
                                        </AccordionItemContent>
                                    </AccordionItem>
                                    <AccordionItem heading={<Tag label={textConfigSettings("section.refusal_mechanism")} icon={{name: "block"}} style={TagStyle.DEFAULT_OCEAN}/>} startOpen>
                                        <AccordionItemContent>
                                            <FormLayoutRows>
                                                <FieldBlock label={textConfigSettings(`${CmpConfigSettingsField.FLEXIBLE_REFUSAL}.label`)}>
                                                    <Box radius={BoxRadius.MD} spacing={BoxSpacing.XSMALL}>
                                                        <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                                                            <Select
                                                                value={cmpConfig.settings.flexibleRefusal}
                                                                options={[
                                                                    {label: textConfigSettings(`${CmpConfigSettingsField.FLEXIBLE_REFUSAL}.${CmpConfigFlexibleRefusalField.ENABLED}`), value: true},
                                                                    {label: textConfigSettings(`${CmpConfigSettingsField.FLEXIBLE_REFUSAL}.${CmpConfigFlexibleRefusalField.DISABLED}`), value: false}
                                                                ]}
                                                                onChange={(option) => handleChangeConfig(CmpConfigSettingsField.FLEXIBLE_REFUSAL, option!.value)}
                                                            />
                                                            <Alert text={textConfigSettings(`${CmpConfigSettingsField.FLEXIBLE_REFUSAL}.${cmpConfig.settings.flexibleRefusal ? CmpConfigFlexibleRefusalField.ENABLED : CmpConfigFlexibleRefusalField.DISABLED}_info`)}/>
                                                        </FlexContent>
                                                    </Box>
                                                </FieldBlock>
                                                <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.NO_CONSENT_BUTTON}.type`)}>
                                                    <Select
                                                        value={cmpConfig.settings.theme.noConsentButton}
                                                        options={CMP_CONFIG_THEME_NO_CONSENT_BUTTONS.map((it) => ({
                                                            value: it,
                                                            label: textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.NO_CONSENT_BUTTON}.type_${it}`)
                                                        }))}
                                                        onChange={(option) => handleChangeConfigThemeNoConsentButton(option?.value as CmpConfigThemeNoConsentButton)}
                                                    />
                                                </FieldBlock>
                                                {cmpConfig.settings.theme.noConsentButton !== CmpConfigThemeNoConsentButton.NONE &&
                                                    <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.NO_CONSENT_BUTTON_STYLE}.label`)}>
                                                        <Select
                                                            value={cmpConfig.settings.theme.noConsentButtonStyle}
                                                            options={CMP_CONFIG_THEME_NO_CONSENT_BUTTON_STYLES.map((it) => ({
                                                                value: it,
                                                                label: textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.NO_CONSENT_BUTTON_STYLE}.${it}`)
                                                            }))}
                                                            onChange={(option) => handleChangeConfigThemeNoConsentButtonStyle(option?.value as CmpConfigThemeNoConsentButtonStyle)}
                                                        />
                                                    </FieldBlock>
                                                }
                                                {cmpConfig.settings.theme.noConsentButton === CmpConfigThemeNoConsentButton.REJECT &&
                                                    <Alert text={textConfigSettings(`theme.${CmpConfigThemeField.NO_CONSENT_BUTTON}.${CmpConfigThemeNoConsentButton.REJECT}_warning`)} severity={AlertSeverity.WARNING}/>
                                                }
                                                <FormLayoutSeparator/>
                                                <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.SET_CHOICES_STYLE}.label`)}>
                                                    <Select
                                                        value={cmpConfig.settings.theme.setChoicesStyle}
                                                        options={CMP_CONFIG_THEME_SET_CHOICES_STYLES.map((it) => ({
                                                            value: it,
                                                            label: textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.SET_CHOICES_STYLE}.${it}`)
                                                        }))}
                                                        onChange={(option) => handleChangeConfig(`${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.SET_CHOICES_STYLE}`, option?.value as CmpConfigThemeSetChoicesStyle)}
                                                        disabled={![CmpConfigThemeNoConsentButtonStyle.BUTTON_BIG_DEFAULT, CmpConfigThemeNoConsentButtonStyle.BUTTON_BIG_PRIMARY].includes(cmpConfig.settings.theme.noConsentButtonStyle as CmpConfigThemeNoConsentButtonStyle)}
                                                    />
                                                </FieldBlock>
                                                {(cmpConfig.settings.theme.noConsentButton !== CmpConfigThemeNoConsentButton.NONE && cmpConfig.settings.theme.noConsentButtonStyle !== CmpConfigThemeNoConsentButtonStyle.CLOSE) &&
                                                    <>
                                                        <FormLayoutSeparator/>
                                                        <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                                                            <FieldBlock label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.CLOSE_BUTTON}`)}>
                                                                <ToggleSwitch
                                                                    name={`${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.CLOSE_BUTTON}`}
                                                                    label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.CLOSE_BUTTON}_label`)}
                                                                    checked={Boolean(cmpConfig.settings.theme.closeButton)}
                                                                    onChange={(value) => handleChangeConfig(`${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.CLOSE_BUTTON}`, value)}
                                                                />
                                                            </FieldBlock>
                                                            {!!cmpConfig.settings.theme.closeButton &&
                                                                <Alert text={textConfigSettings(`theme.${CmpConfigThemeField.CLOSE_BUTTON}.warning`)} severity={AlertSeverity.WARNING}/>
                                                            }
                                                        </FormLayoutRows>
                                                    </>
                                                }
                                            </FormLayoutRows>
                                        </AccordionItemContent>
                                    </AccordionItem>
                                    <AccordionItem heading={<Tag label={textConfigSettings("section.technical_settings")} icon={{name: "build", outlined: true}} style={TagStyle.DEFAULT_OCEAN}/>}>
                                        <AccordionItemContent>
                                            <FormLayoutRows>
                                                <FieldBlock
                                                    label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.SCOPE}.label`)}
                                                    content={{direction: FlexContentDirection.COLUMN}}
                                                >
                                                    <Select
                                                        value={cmpConfig.settings.scope}
                                                        options={CmpConfigScope.values().map((it) => ({value: it.name, label: textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.SCOPE}.${it.name}`)}))}
                                                        onChange={(option) => {
                                                            option && handleChangeConfig(CmpConfigSettingsField.SCOPE, option.value);
                                                        }}
                                                    />
                                                    {cmpConfig.settings.scope === CmpConfigScope.LOCAL.name &&
                                                        <FormLayoutMessage message={textConfigSettings(`${CmpConfigSettingsField.SCOPE}.${CmpConfigScope.LOCAL.name}_warning`)} severity={AlertSeverity.WARNING}/>
                                                    }
                                                </FieldBlock>
                                                <FieldBlock
                                                    label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.COOKIE_MAX_AGE_IN_DAYS}`)}
                                                    content={{noFullWidth: true}}
                                                >
                                                    <InputWithLabel label={textConfigSettings("days")}>
                                                        <InputNumber
                                                            value={cmpConfig.settings.cookieMaxAgeInDays}
                                                            minValue={MIN_COOKIE_MAX_AGE_IN_DAYS}
                                                            maxValue={MAX_COOKIE_MAX_AGE_IN_DAYS}
                                                            onChange={(value) => handleChangeConfig(CmpConfigSettingsField.COOKIE_MAX_AGE_IN_DAYS, value)}
                                                        />
                                                    </InputWithLabel>
                                                </FieldBlock>
                                                <FormLayoutSeparator/>
                                                <FormLayoutTitle>{textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.CAPPING}.label`)}</FormLayoutTitle>
                                                <FlexContent direction={FlexContentDirection.ROW} layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT} spacing={FlexContentSpacing.XSMALL}>
                                                    <Select
                                                        value={cmpConfig.settings.capping}
                                                        options={CmpConfigCapping.values().map((it) => ({value: it.name, label: textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.CAPPING}.${it.name}`)}))}
                                                        onChange={(option) => option && handleChangeConfig(CmpConfigSettingsField.CAPPING, option.value)}
                                                    />
                                                    <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                                                        <InputNumber
                                                            value={cmpConfig.settings.cappingInDays}
                                                            minValue={MIN_CAPPING_IN_DAYS}
                                                            maxValue={MAX_CAPPING_IN_DAYS}
                                                            onChange={(value) => handleChangeConfig(CmpConfigSettingsField.CAPPING_IN_DAYS, value)}
                                                        />
                                                        <span>{textConfigSettings("days")}</span>
                                                    </FlexContent>
                                                </FlexContent>
                                            </FormLayoutRows>
                                        </AccordionItemContent>
                                    </AccordionItem>
                                    <AccordionItem heading={<Tag label={textConfigSettings("section.regulations")} icon={{name: "public"}} style={TagStyle.DEFAULT_OCEAN}/>}>
                                        <AccordionItemContent>
                                            <FormLayoutRows>
                                                <ToggleSwitch
                                                    name={CmpConfigSettingsField.FORCE_GDPR}
                                                    label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.FORCE_GDPR}`)}
                                                    checked={!!cmpConfig.settings.forceGdpr}
                                                    onChange={(value) => handleChangeConfig(CmpConfigSettingsField.FORCE_GDPR, value)}
                                                />
                                                <FormLayoutSeparator/>
                                                <FormLayoutTitle>{textConfigSettings("section.ccpa")}</FormLayoutTitle>
                                                <ToggleSwitch
                                                    name={`${CmpConfigSettingsField.CCPA}.${CmpConfigCCPAField.DISABLED}`}
                                                    label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.CCPA}.${CmpConfigCCPAField.DISABLED}`)}
                                                    checked={isCCPAEnabled}
                                                    onChange={() => handleChangeConfig(`${CmpConfigSettingsField.CCPA}.${CmpConfigCCPAField.DISABLED}`, isCCPAEnabled)}
                                                />
                                                {!isCCPAEnabled &&
                                                    <Alert text={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.CCPA}.${CmpConfigCCPAField.DISABLED}_warning`)} severity={AlertSeverity.WARNING}/>
                                                }
                                                <FieldBlock content={{direction: FlexContentDirection.COLUMN, spacing: FlexContentSpacing.SMALL}}>
                                                    <ToggleSwitch
                                                        name={`${CmpConfigSettingsField.CCPA}.${CmpConfigCCPAField.LSPA}`}
                                                        label={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.CCPA}.${CmpConfigCCPAField.LSPA}`)}
                                                        checked={Boolean(cmpConfig.settings.ccpa.lspa)}
                                                        onChange={(value) => handleChangeConfig(`${CmpConfigSettingsField.CCPA}.${CmpConfigCCPAField.LSPA}`, value)}
                                                    />
                                                    {Boolean(cmpConfig.settings.ccpa.lspa) &&
                                                    <Alert text={textConfiguration(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.CCPA}.${CmpConfigCCPAField.LSPA}_warning`)} severity={AlertSeverity.WARNING}/>
                                                    }
                                                </FieldBlock>
                                                <FormLayoutButtons justify={FlexContentJustify.END}>
                                                    <ButtonLink onClick={() => setShowCCPAPreview(true)} icon={{name: "visibility", outlined: true}} style={ButtonLinkStyle.MIDNIGHT}>
                                                        {textConfigSettings("see_preview")}
                                                    </ButtonLink>
                                                </FormLayoutButtons>
                                            </FormLayoutRows>
                                        </AccordionItemContent>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </Loadable>
                        <Loadable loading={isLoading}>
                            <div>
                                <div className="configuration-sticky-block">
                                    <Preview cmpConfig={cmpConfig} withActions/>
                                </div>
                            </div>
                        </Loadable>
                    </FlexContent>
                </LayoutRows>
                <ModalTranslationTexts
                    active={isActiveModalTranslationTexts}
                    initValue={cmpConfig.settings.texts}
                    onClose={() => setActiveModalTranslationTexts(false)}
                    onSubmit={handleChangeTexts}
                />
                <ModalNew active={isShowCCPAPreview} size={ModalNewSize.MAX_WIDTH} onClose={() => setShowCCPAPreview(false)}>
                    <ModalContent>
                        <ModalTitle>{textConfiguration("modal.ccpa_preview")}</ModalTitle>
                        <Preview cmpConfig={cmpConfig} isCCPA withActions/>
                    </ModalContent>
                </ModalNew>
            </MainContent>
        </Wrapper>
    );
}

export default ConfigSettings;
