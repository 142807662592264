import {ApiModel} from "../../../../common/api/model/ApiModel";

export class CmpConfigVendorList extends ApiModel {
    vendors: number[] | undefined;
    stacks: number[] | undefined;
    disabledPurposes: number[] | undefined;
    disabledSpecialFeatures: number[] | undefined;
    googleProviders: number[] | undefined;
    sirdataVendors: number[] | undefined;
    networks: number[] | undefined;
    consentOnly: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
        if (o === undefined) {
            this.disabledSpecialFeatures = [1, 2];
            this.networks = [1];
            this.stacks = [42];
        }
    }

    getJsonParameters(): {} {
        return {
            vendors: this.vendors,
            stacks: this.stacks,
            disabledPurposes: this.disabledPurposes,
            disabledSpecialFeatures: this.disabledSpecialFeatures,
            googleProviders: this.googleProviders,
            sirdataVendors: this.sirdataVendors,
            networks: this.networks,
            consentOnly: this.consentOnly
        };
    }
}
