import {ApiModel} from "../../../../common/api/model/ApiModel";

export class CmpWebinar extends ApiModel {
    id: number = 0;
    date: string = "";
    _info: Map<string, WebinarInfo> | undefined;
    _faq: Map<string, WebinarFaqItem[]> | undefined;
    status: string = "";
    last_update: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get info(): Map<string, WebinarInfo> | undefined {
        return this._info;
    }

    set info(info: Map<string, WebinarInfo> | undefined) {
        if (!info) {
            return;
        }

        this._info = Object.entries(info).length ? new Map(Object.entries(info)) : info;
    }

    get faq(): Map<string, WebinarFaqItem[]> | undefined {
        return this._faq;
    }

    set faq(faq: Map<string, WebinarFaqItem[]> | undefined) {
        if (!faq) {
            return;
        }

        this._faq = Object.entries(faq).length ? new Map(Object.entries(faq)) : faq;
    }
}

export class WebinarInfo extends ApiModel {
    title: string = "";
    description: string = "";
    cover_url: string = "";
    youtube_id: string = "";
    announcement: string = "";
    is_main_language: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}

export class WebinarFaqItem extends ApiModel {
    question: string = "";
    answer: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
