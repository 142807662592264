import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {SirdataStack} from "./SirdataStack";
import {SirdataVendor} from "./SirdataVendor";
import {SirdataListField} from "./SirdataListField";

export class SirdataList extends ApiModel {
    lastUpdated: string = "";
    stacks: SirdataStack[] = [];
    vendors: SirdataVendor[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        super.load(o);
        this.loadObjects(SirdataListField.STACKS, SirdataStack);
        this.loadObjects(SirdataListField.VENDORS, SirdataVendor);
    }

    getStack(id: number) {
        return this.stacks.find((it) => it.id === id);
    }

    getVendor(id: number) {
        return this.vendors.find((it) => it.id === id);
    }
}
