import {FunctionComponent, useEffect, useState} from "react";
import {Box, BoxProps, BoxRadius, ContentBlock, LayoutColumns, LayoutRows} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {ChartLine} from "../../../component/widget";
import {CHART_DATASET_CONSENT_STYLES, CHART_DATASET_NO_CHOICE_STYLES, CHART_DATASET_OBJECT_STYLES, GraphData, optionsRating} from "../../../utils/stat/chart_options";
import {ChartCardLegend, ChartCardLegendLabels, MetricCard} from "../../../component/snippet";
import {StatPeriod} from "../../../utils/stat/StatPeriod";
import {TranslationPortalFile} from "../../../utils/constants";
import {StatConsentDetails} from "../../../api/model/stat/consent/StatConsentDetails";
import {AnalyticsConsentDetailsTab} from "./AnalyticsConsentDetails";

type AnalyticsGlobalDetailsGlobalProps = {
    statsDetails: StatConsentDetails;
    period: StatPeriod;
    onClickDevice: (tab: AnalyticsConsentDetailsTab) => void;
};

const AnalyticsGlobalDetailsGlobal: FunctionComponent<AnalyticsGlobalDetailsGlobalProps> = ({statsDetails, period, onClickDevice}) => {
    const {t: textAnalytics} = useTranslation(TranslationPortalFile.ANALYTICS);
    const [rateGraphData, setRateGraphData] = useState<GraphData>();

    useEffect(() => {
        setRateGraphData({
            labels: statsDetails.dates,
            datasets: [{
                label: textAnalytics("metric.consent"),
                data: statsDetails.global.daily.consent,
                ...CHART_DATASET_CONSENT_STYLES
            }, {
                label: textAnalytics("metric.object"),
                data: statsDetails.global.daily.object,
                ...CHART_DATASET_OBJECT_STYLES
            }, {
                label: textAnalytics("metric.no_choice"),
                data: statsDetails.global.daily.no_choice,
                ...CHART_DATASET_NO_CHOICE_STYLES
            }]
        });
    }, [statsDetails, textAnalytics]);

    return (
        <LayoutRows>
            <LayoutColumns>
                <MetricCard
                    header={{label: textAnalytics("metric.consent")}}
                    value={`${statsDetails.global.ratings.consent}%`}
                    trendValue={statsDetails.global.trends.consent}
                    showTrend={period.name !== StatPeriod.CUSTOM.name}
                />
                <MetricCard
                    header={{label: textAnalytics("metric.object")}}
                    value={`${statsDetails.global.ratings.object}%`}
                    trendValue={statsDetails.global.trends.object}
                    showTrend={period.name !== StatPeriod.CUSTOM.name}
                />
                <MetricCard
                    header={{label: textAnalytics("metric.no_choice")}}
                    value={`${statsDetails.global.ratings.no_choice}%`}
                    trendValue={statsDetails.global.trends.no_choice}
                    showTrend={period.name !== StatPeriod.CUSTOM.name}
                />
            </LayoutColumns>
            <ContentBlock header={{title: {label: textAnalytics("evolution_by_rating")}}}>
                <Box {...BoxProps.SECTION_BLOCK} radius={BoxRadius.MD} cssClass="chart-card">
                    <ChartCardLegend
                        labels={rateGraphData?.datasets.map((dataset) => ({value: dataset.label, color: dataset.borderColor})) as ChartCardLegendLabels[]}
                        alignRight
                    />
                    <ChartLine
                        data={rateGraphData}
                        options={optionsRating}
                        width={100}
                        height={35}
                    />
                </Box>
            </ContentBlock>
            <ContentBlock header={{title: {label: textAnalytics("consent_per_device")}}}>
                <LayoutColumns>
                    <MetricCard
                        header={{label: textAnalytics("metric.desktop"), image: "/images/laptop.png"}}
                        value={`${statsDetails.desktop.ratings.consent}%`}
                        trendValue={statsDetails.desktop.trends.consent}
                        showTrend={period.name !== StatPeriod.CUSTOM.name}
                        onClick={() => onClickDevice(AnalyticsConsentDetailsTab.DESKTOP)}
                    />
                    <MetricCard
                        header={{label: textAnalytics("metric.mobile"), image: "/images/mobile.png"}}
                        value={`${statsDetails.mobile.ratings.consent}%`}
                        trendValue={statsDetails.mobile.trends.consent}
                        showTrend={period.name !== StatPeriod.CUSTOM.name}
                        onClick={() => onClickDevice(AnalyticsConsentDetailsTab.MOBILE)}
                    />
                    <MetricCard
                        header={{label: textAnalytics("metric.tablet"), image: "/images/tablette.png"}}
                        value={`${statsDetails.tablet.ratings.consent}%`}
                        trendValue={statsDetails.tablet.trends.consent}
                        showTrend={period.name !== StatPeriod.CUSTOM.name}
                        onClick={() => onClickDevice(AnalyticsConsentDetailsTab.TABLET)}
                    />
                </LayoutColumns>
            </ContentBlock>
        </LayoutRows>
    );
};

export default AnalyticsGlobalDetailsGlobal;
