import React, {Suspense, useEffect} from "react";
import {createBrowserHistory} from "history";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {ScrollToTop} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

import PrivateRoute from "./component/guard/PrivateRoute";
import {
    Access,
    AnalyticsConsent,
    AnalyticsConsentDetails,
    AnalyticsHits,
    BarterAgreement,
    Cmp,
    ConfigAddOns,
    ConfigBanner,
    ConfigImages,
    ConfigIntegration,
    ConfigPartners,
    ConfigPurposes,
    ConfigSettings,
    ConfigurationFlashInformation,
    ConfigurationFlashPreview,
    ConfigurationMode,
    GoogleErrors,
    Home,
    LegalNotice,
    Login,
    Sso,
    TermsOfSale
} from "./screen";
import {
    pathAnalytics,
    pathAnalyticsConsent,
    pathAnalyticsConsentWithId,
    pathAnalyticsHits,
    pathAnalyticsHitsWithId,
    pathBarterAgreement,
    pathCmp,
    pathConfigurationFlashInformation,
    pathConfigurationFlashPreview,
    pathConfigurationMode,
    pathGoogleErrors,
    pathTermsOfSale,
    TranslationPortalFile
} from "./utils/constants";
import {pathAccess, pathError403, pathError404, pathError500, pathHome, pathLegalNotice, pathLogin, pathSso} from "./common/utils/constants";
import {Error403, Error404, Error500} from "./common/screen";
import {session} from "./api/ApiSession";
import {SirdataApiEvent} from "./common/api/CommonApiClient";
import {OnboardingTool} from "./component/snippet";
import {Origin} from "./common/api/model/Origin";
import {PORTAL} from "./utils/Portal";
import {usePreprod} from "./common/utils/helper";
import {QueryString} from "./common/api/model/QueryString";
import CmpConfigStepper, {CmpConfigStepName} from "./utils/CmpConfigStepper";
import ModalSupport from "./component/modal/ModalSupport";

const history = createBrowserHistory();

function App() {
    const {t, ready} = useTranslation(TranslationPortalFile.TRANSLATION, {useSuspense: false});

    useEffect(() => {
        if (ready) {
            document.title = t("head.title");
        }
    }, [t, ready]);

    session.on(SirdataApiEvent.eventNotFound, () => {
        history.replace(pathError404);
    });

    return (
        <React.StrictMode>
            <Suspense fallback={<></>}>
                <Router history={history}>
                    <ScrollToTop/>
                    <Switch>
                        <Route exact path={pathSso} component={Sso}/>
                        <Route exact path={pathError403} component={Error403}/>
                        <Route exact path={pathError404} component={Error404}/>
                        <Route exact path={pathError500} component={Error500}/>

                        <Route exact path="/" component={() => <Redirect to={pathLogin}/>}/>
                        <Route exact path={pathLogin} component={Login}/>
                        <Route exact path={pathBarterAgreement} component={BarterAgreement}/>
                        <Route exact path={[pathTermsOfSale, "/terms-of-use"]} component={TermsOfSale}/>
                        <Route exact path={pathLegalNotice} component={LegalNotice}/>
                        <Route exact path={pathAccess} component={Access}/>
                        <Route exact path={pathGoogleErrors} component={GoogleErrors}/>

                        <PrivateRoute exact path={pathHome} component={Home} requiredAuthorization={undefined}/>
                        <PrivateRoute exact path={pathCmp} component={Cmp} requiredAuthorization={undefined}/>

                        <PrivateRoute path={CmpConfigStepper.getStep(CmpConfigStepName.BANNER)?.path} component={ConfigBanner} requiredAuthorization={undefined}/>
                        <PrivateRoute path={CmpConfigStepper.getStep(CmpConfigStepName.IMAGES)?.path} component={ConfigImages} requiredAuthorization={undefined}/>
                        <PrivateRoute path={CmpConfigStepper.getStep(CmpConfigStepName.SETTINGS)?.path} component={ConfigSettings} requiredAuthorization={undefined}/>
                        <PrivateRoute path={CmpConfigStepper.getStep(CmpConfigStepName.ADD_ONS)?.path} component={ConfigAddOns} requiredAuthorization={undefined}/>
                        <PrivateRoute path={CmpConfigStepper.getStep(CmpConfigStepName.PARTNERS)?.path} component={ConfigPartners} requiredAuthorization={undefined}/>
                        <PrivateRoute path={CmpConfigStepper.getStep(CmpConfigStepName.PURPOSES)?.path} component={ConfigPurposes} requiredAuthorization={undefined}/>
                        <PrivateRoute path={CmpConfigStepper.getStep(CmpConfigStepName.INTEGRATION)?.path} component={ConfigIntegration} requiredAuthorization={undefined}/>

                        <PrivateRoute exact path={pathAnalytics} component={() => <Redirect to={pathAnalyticsConsent}/>} requiredAuthorization={undefined}/>
                        <PrivateRoute exact path={pathAnalyticsConsent} component={AnalyticsConsent} requiredAuthorization={undefined}/>
                        <PrivateRoute exact path={pathAnalyticsConsentWithId} component={AnalyticsConsentDetails} requiredAuthorization={undefined}/>
                        <PrivateRoute exact path={pathAnalyticsHits} component={AnalyticsHits} requiredAuthorization={undefined}/>
                        <PrivateRoute exact path={pathAnalyticsHitsWithId} component={AnalyticsHits} requiredAuthorization={undefined}/>

                        <PrivateRoute path={pathConfigurationMode} component={ConfigurationMode} requiredAuthorization={undefined}/>
                        <PrivateRoute path={pathConfigurationFlashInformation} component={ConfigurationFlashInformation} requiredAuthorization={undefined}/>
                        <PrivateRoute path={pathConfigurationFlashPreview} component={ConfigurationFlashPreview} requiredAuthorization={undefined}/>

                        <Route
                            exact
                            path="/register"
                            component={() => {
                                window.location.href = `${Origin.ACCOUNT.getUrl(usePreprod)}/register?${QueryString.ORIGIN}=${PORTAL.origin.name}`;
                                return null;
                            }}
                        />

                        <Route component={() => <Redirect to={pathError404}/>}/>
                    </Switch>
                    <ModalSupport/>
                    <OnboardingTool/>
                </Router>
            </Suspense>
        </React.StrictMode>
    );
}

export default App;
