export enum CmpConfigExternalField {
    GOOGLE_AD_SENSE = "googleAdSense",
    GOOGLE_AD_MANAGER = "googleAdManager",
    GOOGLE_ANALYTICS = "googleAnalytics",
    GTM_DATA_LAYER = "gtmDataLayer",
    AUDIENCE_PROFILING = "audienceProfiling",
    SHOPIFY = "shopify",
    MICROSOFT_UET = "microsoftUet",
    CONSENT_MODE_FROM_TCF = "consentModeFromTcf"
}
