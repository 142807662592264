import {useEffect, useState} from "react";
import {Alert, AlertSeverity, Button, ButtonAdd, ButtonSize, ContentBlock, EmptyContentMessage, LayoutRows, Loadable, MainContentPageHeader, SearchError, SearchToolbar, SvgUnderConstruction, Table, TableColumnStyle} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import CmpUpgradeTransition from "../utils/CmpUpgradeTransition";
import ModalConfigAdvancedInfo, {ModalConfigAdvancedInfoUiEvent} from "../component/modal/ModalConfigAdvancedInfo";
import ModalConfigMode from "../component/modal/ModalConfigMode";
import ModalConfigFlashInfo from "../component/modal/ModalConfigFlashInfo";
import ModalDeleteConfig from "../component/modal/ModalDeleteConfig";
import {ConfigurationModeName} from "../utils/ConfigurationMode";
import {CmpConfig} from "../api/model/cmp/config/CmpConfig";
import {CmpConfigStatus} from "../api/model/cmp/config/CmpConfigStatus";
import {CmpRow} from "../component/snippet";
import {MainContent, Wrapper} from "../common/component/widget";
import {MainHeader} from "../common/component/snippet";
import {PAGE_SIZE, pathCmp, TranslationPortalFile} from "../utils/constants";
import {PortalQueryString} from "../api/model/PortalQueryString";
import {session} from "../api/ApiSession";
import {UIEventManager} from "../common/utils/UIEventManager";
import {CmpConfigField} from "../api/model/cmp/config/CmpConfigField";
import {CmpConfigPropertiesCreationType} from "../api/model/cmp/config/CmpConfigPropertiesCreationType";
import {CmpConfigPropertiesField} from "../api/model/cmp/config/CmpConfigPropertiesField";

function Cmp() {
    const history = useHistory();
    const location = useLocation();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONFIGURATION);

    const [cmpConfigs, setCmpConfigs] = useState<CmpConfig[]>([]);
    const [currentCmpConfigs, setCurrentCmpConfigs] = useState<CmpConfig[]>([]);
    const [currentDeleteCmp, setCurrentDeleteCmp] = useState<CmpConfig>();
    const [query, setQuery] = useState("");

    const [isLoading, setLoading] = useState(true);
    const [isShowModalConfigFlashInfo, setShowModalConfigFlashInfo] = useState(false);
    const [isShowModalConfigMode, setShowModalConfigMode] = useState(false);

    const [hasOutdatedConfig, setHasOutdatedConfig] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                await session.loadAccount();

                const cmpConfigs = await session.restCmpConfig.list();
                setCmpConfigs(cmpConfigs);
                setCurrentCmpConfigs(cmpConfigs);

                const cmpId = PortalQueryString.getParam(location.search, PortalQueryString.ID);
                if (!!cmpId?.length) {
                    const cmpConfig = cmpConfigs.find((it) => it.id === cmpId);
                    UIEventManager.emit(ModalConfigAdvancedInfoUiEvent, {
                        cmpConfig: cmpConfig ? cmpConfig : new CmpConfig(),
                        onClose: () => history.push(pathCmp)
                    });
                }

                setHasOutdatedConfig(await session.hasOutdatedConfig());
            } catch (e) {
            } finally {
                setLoading(false);
            }
        })();
    }, [location, history]);

    useEffect(() => {
        const newCurrentCmpConfigs = cmpConfigs.filter((item) => !query || `${item.name} ${item.domain}`.toLowerCase().includes(query.toLowerCase()));
        setCurrentCmpConfigs(newCurrentCmpConfigs);
    }, [query, cmpConfigs]);

    const onChangeQuery = (query: string) => {
        setQuery(query);
    };

    const handleAdd = () => {
        setShowModalConfigMode(true);
    };

    const handleDuplicate = async (cmpConfig: CmpConfig) => {
        try {
            const newCmpConfig = new CmpConfig({
                ...cmpConfig,
                [CmpConfigField.ID]: "",
                [CmpConfigField.NAME]: `${cmpConfig.name} (${textConfiguration("copy")})`,
                [CmpConfigField.PROPERTIES]: {
                    [CmpConfigPropertiesField.CREATION_TYPE]: CmpConfigPropertiesCreationType.DUPLICATE
                }
            });

            const createdCmpConfig = await session.restCmpConfig.create(newCmpConfig);
            const newCmpConfigs = [...cmpConfigs, createdCmpConfig];
            setCmpConfigs(newCmpConfigs);
        } catch (e) {
            UIEventManager.alert(textConfiguration("error.duplicate_cmp"), AlertSeverity.DANGER);
        }
    };

    const handleToggleStatus = async (cmpConfig: CmpConfig) => {
        try {
            const newCmpConfigs = [...cmpConfigs];
            cmpConfig.status = cmpConfig.status === CmpConfigStatus.ACTIVE ? CmpConfigStatus.INACTIVE : CmpConfigStatus.ACTIVE;
            await session.restCmpConfig.update(cmpConfig);
            setCmpConfigs(newCmpConfigs);
        } catch (e) {
            UIEventManager.alert(textConfiguration("error.update_cmp_status"), AlertSeverity.DANGER);
        }
    };

    const handleDelete = async (cmpConfig: CmpConfig) => {
        if (!cmpConfig) return;
        try {
            await session.restCmpConfig.delete(cmpConfig.id);
            const newCmpConfigs = [...cmpConfigs];
            newCmpConfigs.splice(newCmpConfigs.findIndex((item) => item.id === cmpConfig.id), 1);
            setCmpConfigs(newCmpConfigs);
            setCurrentDeleteCmp(undefined);
        } catch (e) {
            UIEventManager.alert(textConfiguration("error.delete_cmp"), AlertSeverity.DANGER);
        }
    };

    const handleConfigModeChoice = (mode: ConfigurationModeName) => {
        switch (mode) {
            case ConfigurationModeName.ADVANCED:
                UIEventManager.emit(ModalConfigAdvancedInfoUiEvent, {cmpConfig: new CmpConfig(), onClose: () => history.push(pathCmp)});
                break;
            case ConfigurationModeName.FLASH:
                setShowModalConfigFlashInfo(true);
                break;
            default:
                break;
        }
        setShowModalConfigMode(false);
    };

    const buildTableColumns = () => {
        return [
            {width: 10, label: textConfiguration(`field.${CmpConfigField.STATUS}`)},
            {width: 10, label: textConfiguration(`field.${CmpConfigField.VERSION}`)},
            {width: 10, label: textConfiguration(`field.${CmpConfigField.ID}`)},
            {width: 30, label: textConfiguration(`field.${CmpConfigField.NAME}`)},
            {width: 30, label: textConfiguration(`field.${CmpConfigField.DOMAIN}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
            {width: 10, styles: TableColumnStyle.ALIGN_CENTER}
        ];
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <MainContentPageHeader title={t("list.protected_sites")}/>
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        {hasOutdatedConfig && <Alert text={t("list.update_alert", {version: CmpUpgradeTransition.newVersion.label})}/>}
                        {!!cmpConfigs.length ?
                            <ContentBlock>
                                <SearchToolbar
                                    searchBar={{placeholder: textConfiguration("search.site"), value: query, onChange: onChangeQuery}}
                                    actions={[<ButtonAdd key={0} size={ButtonSize.MEDIUM} onClick={handleAdd}/>]}
                                />
                                {!!currentCmpConfigs.length ?
                                    <Table columns={buildTableColumns()} pagination={PAGE_SIZE}>
                                        {currentCmpConfigs.map((item: CmpConfig) =>
                                            <CmpRow
                                                key={item.id}
                                                cmp={item}
                                                onPublish={() => handleToggleStatus(item)}
                                                onCopy={() => handleDuplicate(item)}
                                                onDelete={() => setCurrentDeleteCmp(item)}
                                            />)
                                        }
                                    </Table> :
                                    <SearchError query={query}/>
                                }
                            </ContentBlock> :
                            <EmptyContentMessage svg={SvgUnderConstruction} message={textConfiguration("empty_content.message")}>
                                <Button onClick={handleAdd} size={ButtonSize.BIG}>
                                    {textConfiguration("empty_content.start")}
                                </Button>
                            </EmptyContentMessage>
                        }
                    </Loadable>
                </LayoutRows>
                <ModalConfigMode
                    active={isShowModalConfigMode}
                    onClose={() => setShowModalConfigMode(false)}
                    onClick={handleConfigModeChoice}
                />
                <ModalConfigAdvancedInfo/>
                <ModalConfigFlashInfo
                    active={isShowModalConfigFlashInfo}
                    onClose={() => setShowModalConfigFlashInfo(false)}
                />
                <ModalDeleteConfig
                    cmpConfig={currentDeleteCmp}
                    onClose={() => setCurrentDeleteCmp(undefined)}
                    onDelete={() => handleDelete(currentDeleteCmp!)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default Cmp;
