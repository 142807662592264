import {FunctionComponent, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Locale} from "../../common/utils/Locale";
import {LoggedAccount} from "../../common/api/interface/LoggedAccount";
import {session} from "../../api/ApiSession";
import {TranslationPortalFile} from "../../utils/constants";
import {Action, TranslationLibFile} from "@sirdata/ui-lib";

export const GOOGLE_SUPPORT_FORM_ID = "#google-support-form";
export const SUPPORT_FORM_ID = "#support-form";
export const SUPPORT_FORM_BODY_ID = "[name='body']";

const defaultZammadForm = {
    lang: Locale.getCurrentLocale(),
    showTitle: true,
    modal: true,
    attributes: [
        {
            display: "Name",
            name: "name",
            tag: "input",
            type: "text",
            placeholder: "Your name"
        },
        {
            display: "Email",
            name: "email",
            tag: "input",
            type: "email",
            placeholder: "Your email"
        },
        {
            display: "Message",
            name: "body",
            tag: "textarea",
            placeholder: "Your message...",
            defaultValue: "",
            rows: 7
        }
    ],
    translations: {
        "fr": {
            "Name": "Nom",
            "Your name": "Votre nom",
            "Email": "Email",
            "Your email": "Votre email",
            "Message": "Message",
            "Your message...": "Votre message..."
        }
    }
};

const SupportForm: FunctionComponent = () => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textSupport} = useTranslation(TranslationPortalFile.SUPPORT);
    const [account, setAccount] = useState<LoggedAccount>();

    const initSupportForm = useCallback((account: LoggedAccount) => {
        ($(SUPPORT_FORM_ID) as any).ZammadForm({
            ...defaultZammadForm,
            messageTitle: textSupport("title"),
            messageSubmit: textCommon(Action.SEND.labelKey),
            messageThankYou: textSupport("confirm"),
            attributes: [
                {
                    ...defaultZammadForm.attributes[0],
                    defaultValue: account.fullName || account.email
                },
                {
                    ...defaultZammadForm.attributes[1],
                    defaultValue: account.email
                },
                defaultZammadForm.attributes[2]
            ]
        });
    }, [textCommon, textSupport]);

    const initGoogleSupportForm = useCallback((account: LoggedAccount) => {
        ($(GOOGLE_SUPPORT_FORM_ID) as any).ZammadForm({
            ...defaultZammadForm,
            messageTitle: textSupport("title"),
            messageSubmit: textCommon(Action.SEND.labelKey),
            messageThankYou: textSupport("confirm_google"),
            attributes: [
                {
                    ...defaultZammadForm.attributes[0],
                    defaultValue: account.fullName || account.email
                },
                {
                    ...defaultZammadForm.attributes[1],
                    defaultValue: account.email
                },
                defaultZammadForm.attributes[2]
            ]
        });
    }, [textCommon, textSupport]);

    useEffect(() => {
        if (!account) {
            (async () => {
                try {
                    const account = await session.getAccount();
                    setAccount(account);
                    initSupportForm(account);
                    initGoogleSupportForm(account);
                } catch (e) {
                }
            })();
        }
    }, [account, initSupportForm, initGoogleSupportForm]);

    return (
        <>
            <span id="support-form"/>
            <span id="google-support-form"/>
        </>
    );
};

export default SupportForm;
