import {Rest} from "../../common/api/rest/Rest";
import {GlobalVendorList} from "../model/cmp/list/global-vendor-list/GlobalVendorList";
import {GoogleACProviderList} from "../model/cmp/list/google-ac-provider-list/GoogleACProviderList";
import {NetworkList} from "../model/cmp/list/network-list/NetworkList";
import {SirdataList} from "../model/cmp/list/sirdata-list/SirdataList";
import {FreemiumList} from "../model/cmp/list/FreemiumList";
import {Locale} from "../../common/utils/Locale";
import {ApiClient} from "../ApiClient";
import {CmpConfigLanguage} from "../model/cmp/config/CmpConfigLanguage";
import {GlobalVendorListVersion} from "../model/cmp/list/global-vendor-list/GlobalVendorListVersion";

export class RestList extends Rest {
    public get rootPath(): string {
        return `${(this._client as ApiClient).gatewayRoot}/list`;
    }

    async getGlobalVendorList(): Promise<GlobalVendorList> {
        return this._client.get(`${this.rootPath}/gvl/${GlobalVendorListVersion.V3}?lang=${CmpConfigLanguage.getByName(Locale.getCurrentLocale()).name}`, GlobalVendorList) as Promise<GlobalVendorList>;
    }

    async getGoogleACProviderList(): Promise<GoogleACProviderList> {
        return this._client.get(`${this.rootPath}/google-ac`, GoogleACProviderList) as Promise<GoogleACProviderList>;
    }

    async getNetworkList(): Promise<NetworkList> {
        return this._client.get(`${this.rootPath}/networks`, NetworkList) as Promise<NetworkList>;
    }

    async getSirdataList(): Promise<SirdataList> {
        return this._client.get(`${this.rootPath}/sirdata`, SirdataList) as Promise<SirdataList>;
    }

    async getFreemiumList(): Promise<FreemiumList> {
        return this._client.get(`${this.rootPath}/freemium`, FreemiumList) as Promise<FreemiumList>;
    }
}
