import {Locale} from "../common/utils/Locale";

const usePreprod = window.location.origin.includes("localhost") || window.location.origin.includes("-preprod.sirdata.io");

export const sirdataDataPrivacyPolicyUrl = Locale.isFrench() ? "https://www.sirdata.com/fr/Vie-Privee-Marketing" : "https://www.sirdata.com/en/Privacy-Marketing";

export const cmpUiRoot = usePreprod ? "https://preprod.ui.consentframework.com" : "https://ui.consentframework.com";
export const cmpFrontEndRoot = usePreprod ? "https://choices-preprod.consentframework.com" : "https://choices.consentframework.com";
export const cmpFrontEndCacheRoot = usePreprod ? "https://cache-preprod.consentframework.com" : "https://cache.consentframework.com";
export const cmpApiRoot = usePreprod ? "https://api-preprod.consentframework.com" : "https://api.consentframework.com";

export const sirdataAboutUrl = Locale.isFrench() ? "https://www.sirdata.com/fr/" : "https://www.sirdata.com/en/";
export const sirdataCmpPricingUrl = Locale.isFrench() ? "https://www.sirdata.com/fr/CMP-Tarifs" : "https://www.sirdata.com/en/CMP-Pricing";
export const sirdataDocumentationUrl = Locale.isFrench() ? "https://cmp.docs.sirdata.net/" : "https://cmp.docs.sirdata.net/v/en/";
export const sirdataDocumentationTagManagementUrl = Locale.isFrench() ? "https://cmp.docs.sirdata.net/gestion-des-scripts/exemples" : "https://cmp.docs.sirdata.net/v/en/script-management/examples";
export const sirdataFaqUrl = Locale.isFrench() ? "https://cmp.docs.sirdata.net/faq" : "https://cmp.docs.sirdata.net/v/en/faq";
export const sirdataCmpNewDesignUrl = Locale.isFrench() ? "https://news.sirdata.com/sirdata-cmp-nouveaute/" : "https://news.sirdata.com/sirdata-cmp-a-new-design/";

export const googleConsentModeUrl = `https://support.google.com/analytics/answer/9976101?hl=${Locale.isFrench() ? "fr" : "en"}`;
export const googleAnalyticsCnilWarningUrl = Locale.isFrench() ? "https://www.cnil.fr/fr/utilisation-de-google-analytics-et-transferts-de-donnees-vers-les-etats-unis-la-cnil-met-en-demeure" : "https://www.cnil.fr/en/use-google-analytics-and-data-transfers-united-states-cnil-orders-website-manageroperator-comply";
export const googleAdManagerDocUrl = Locale.isFrench() ? "https://cmp.docs.sirdata.net/gestion-des-scripts/exemples#google-ad-manager" : "https://cmp.docs.sirdata.net/v/en/script-management/examples#google-ad-manager";
export const microsoftConsentModeUrl = `https://help.ads.microsoft.com/#apex/ads/${Locale.isFrench() ? "fr" : "en"}/consent%20mode`;

export const defaultSkinImageUrl = usePreprod ? "https://dev.cdn.sirdata.io/cmp/images/theme/skin/1-cookies.png" : "https://cdn.sirdata.io/cmp/images/theme/skin/1-cookies.png";
export const defaultWatermarkImageUrl = usePreprod ? "https://dev.cdn.sirdata.io/cmp/images/theme/watermark/1-cookie.png" : "https://cdn.sirdata.io/cmp/images/theme/watermark/1-cookie.png";
